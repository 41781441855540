import React from 'react';
import { S } from './OverviewStatistics.styles';
import { useTable } from 'react-table';
import { useOverviewStatisticsTableData } from './hooks/useOverviewStatisticsTableData';
import { useGetStatistics } from '../../../../../../traits/api/controls/useGetStatistics';

export const OverviewStatistics = ({
  controlId,
  inspectionId,
}: {
  controlId: string;
  inspectionId: string;
}) => {
  const { data: controlStatistics } = useGetStatistics(inspectionId, controlId);
  const data = useOverviewStatisticsTableData(controlStatistics);
  const table = useTable(data);

  const [showInfo, setShowInfo] = React.useState('');

  if (data.data.length === 0) {
    return <></>;
  }

  return (
    <S.TWrapper>
      <S.Table {...table.getTableProps()}>
        <S.THead>
          {table.headerGroups.map((headerGroup) => (
            <S.THeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.THeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.B3 $color="black">{column.render('Header')}</S.B3>
                  </S.THeadCell>
                );
              })}
            </S.THeadRow>
          ))}
        </S.THead>

        <S.TBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.TBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <S.TBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.column.id === 'statisticsDescription' ? (
                        <S.Flex>
                          <div style={{ position: 'relative' }}>
                            {/* Melvin lös nedan */}
                            {showInfo === row.id && (
                              <S.Flex
                                $align={'center'}
                                $justify={'left'}
                                style={{
                                  position: 'absolute',
                                  padding: 10,
                                  top: -55,
                                  left: 0,
                                  height: 40,
                                  width: 400,
                                  backgroundColor: '#1E2D3B',
                                }}
                              >
                                <S.B1 $color="white">
                                  Information om avvikelse kommer
                                </S.B1>
                              </S.Flex>
                            )}
                          </div>
                          <S.Icon
                            src="Info"
                            onMouseLeave={() => setShowInfo('')}
                            onMouseEnter={() => {
                              setShowInfo(row.id);
                            }}
                          />
                          {cell.render('Cell')}
                        </S.Flex>
                      ) : (
                        cell.render('Cell')
                      )}
                    </S.TBodyCell>
                  );
                })}
              </S.TBodyRow>
            );
          })}
        </S.TBody>
      </S.Table>
    </S.TWrapper>
  );
};
