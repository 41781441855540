export const markdownParser = (text: string) => {
  let toHTML = text;

  // H6
  toHTML = toHTML.replace(
    /^###### (.*$)/gim,
    "<h6 style='font-size: 14px;'>$1</h6>"
  );

  // H5
  toHTML = toHTML.replace(
    /^##### (.*$)/gim,
    "<h5 style='font-size: 18px;'>$1</h5>"
  );

  // H4
  toHTML = toHTML.replace(
    /^#### (.*$)/gim,
    "<h4 style='font-size: 20px;'>$1</h4>"
  );

  // H3
  toHTML = toHTML.replace(
    /^### (.*$)/gim,
    "<h3 style='font-size: 24px; font-weight: 500;'>$1</h3>"
  );

  // H2
  toHTML = toHTML.replace(
    /^## (.*$)/gim,
    "<h2 style='font-size: 30px;  line-height: 32px; font-weight: 500;'>$1</h2>"
  );

  // H1
  toHTML = toHTML.replace(
    /^# (.*$)/gim,
    "<h1 style='font-size: 40px; line-height: 48px; font-weight: 500'>$1</h1>"
  );

  //Italic bold
  toHTML = toHTML.replace(/\*\*_(.*?)_\*\*/gim, '<b><i>$1</i></b>'); // italic bold

  // Bold
  toHTML = toHTML.replace(
    /\*\*(.*)\*\*/gim,
    "<span style='font-weight: 500;'>$1</span>"
  ); // bold

  // Italic
  toHTML = toHTML.replace(/(?:[ ])\_(.*?)\_/gim, '<i>$1</i>'); // italic

  // Italic
  toHTML = toHTML.replace(/^\_(.*?)\_/gim, '<i>$1</i>'); // italic

  // Strike through
  toHTML = toHTML.replace(/\~~(.*)\~~/gim, '<s>$1</s>'); // strike through

  // List item ul
  toHTML = toHTML.replace(
    /^\-(.*)/gim,
    "<div style='display: flex; flex-direction: row; align-items: flex-start; margin: 4px 0px;'><div style='background: #C8AB5D; height: 8px; width: 8px; border-radius: 16px; margin-right: 16px; margin-top: 5px;'></div><li style='width: 90%; list-style-type: none;'>$1</li></div>"
  );

  // List item ol
  toHTML = toHTML.replace(
    /^(\d+)\.*(.*)/gm,
    '<li style="margin-bottom: 8px">$1. $2</li>'
  );

  // Img
  toHTML = toHTML.replace(
    /^!\[(.*)\]\((.*)\)/gm,
    "<img style='margin: 0 0 12px;' src='$2' alt='$1' />"
  );

  // Link
  toHTML = toHTML.replace(/\[(.*?)\]+\((.*?)\)/gm, "<a href='$2'>$1</a>");

  // Quote
  toHTML = toHTML.replace(/^>(.*)/gim, '<blockquote>$1</blockquote>');

  // Paragraph
  toHTML = toHTML.replace(
    /^(?!<h[1-6]|<ul|<ol|<li|<blockquote|<img|<div|<a|<br|<i|<b|<s).*$/gm,
    "<p style='margin: 0px 0px 12px;'>$&</p>"
  );

  // Line break
  toHTML = toHTML.replace(
    /^<br ?\/?>(.*$)/gim,
    '<div style="width: 100%; height: 12px;"></div>'
  );

  return toHTML.trim(); // using trim method to remove whitespace
};
