import React from 'react';

import { S } from './Pagination.styles';

type Props = {
  numberOfPages: number;
  page: number;
  setPage: (page: number) => void;
};

export const Pagination: React.FC<Props> = (props) => {
  const paginationPages = Math.min(props.numberOfPages, 5);

  const nextPage = () => {
    if (props.page < props.numberOfPages - 1) {
      props.setPage(props.page + 1);
    }
  };

  const previousPage = () => {
    if (props.page > 0) {
      props.setPage(props.page - 1);
    }
  };

  if (props.numberOfPages <= 1) {
    return null;
  }

  return (
    <S.PaginationContainer>
      <S.PaginationPrevButton disabled={props.page < 1} onClick={previousPage}>
        <S.Icon src="ChevronLeft" color="secondary" />

        <S.B2 $weight={500} $color="secondary">
          Föregående
        </S.B2>
      </S.PaginationPrevButton>

      {Array.from({ length: paginationPages }, (_, i) => {
        let displayPage: number;

        if (props.numberOfPages < 5) {
          displayPage = i;
        } else if (props.page < 2) {
          displayPage = i;
        } else if (props.page >= props.numberOfPages - 2) {
          displayPage = props.numberOfPages - 5 + i;
        } else {
          displayPage = props.page - 2 + i;
        }

        return (
          <S.PaginationButton
            key={displayPage}
            $isActive={props.page === displayPage}
            onClick={() => props.setPage(displayPage)}
          >
            {displayPage + 1}
          </S.PaginationButton>
        );
      })}

      <S.PaginationNextButton
        disabled={props.page >= props.numberOfPages - 1}
        onClick={nextPage}
      >
        <S.B2 $weight={500} $color="secondary">
          Nästa
        </S.B2>

        <S.Icon src="ChevronRight" color="secondary" />
      </S.PaginationNextButton>
    </S.PaginationContainer>
  );
};
