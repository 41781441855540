import { FindAllCompanyStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';

type CountryStats = {
  controlledAndSignedIn: number;
  controlledNotSignedIn: number;
  notFound: number;
  id06CardDeviating: number;
  safeConstructionTraining: number;
  invalidIdentification: number;
  safetyEquipment: number;
  workPermit: number;
};

type Props = {
  statsCountry: CountryStats | null;
  stats: FindAllCompanyStatisticsResponse[];
};

export function useContractorsStatistics({ statsCountry, stats }: Props) {
  const data = useMemo(() => {
    const labelX = stats.map((stat) => stat.company.name).concat('Landet');
    const dataX1 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfControlledAndSignedInPersonnel /
            stat.numberOfControlledPersonnel) *
          100
    );

    dataX1.push(statsCountry ? statsCountry.controlledAndSignedIn : 0);

    const dataX2 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : ((stat.numberOfControlledPersonnel -
            stat.numberOfControlledAndSignedInPersonnel) /
            stat.numberOfControlledPersonnel) *
          100
    );
    dataX2.push(statsCountry ? statsCountry.controlledNotSignedIn : 0);

    const dataX3 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfNotFoundPersonnel / stat.numberOfControlledPersonnel) *
          100
    );
    dataX3.push(statsCountry ? statsCountry.notFound : 0);

    const dataX4 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfDeviatingId06Cards / stat.numberOfControlledPersonnel) *
          100
    );
    dataX4.push(statsCountry ? statsCountry.id06CardDeviating : 0);

    const dataX5 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfPersonnelWithoutSafeConstructionTraining /
            stat.numberOfControlledPersonnel) *
          100
    );
    dataX5.push(statsCountry ? statsCountry.safeConstructionTraining : 0);

    const dataX6 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfPersonnelWithInvalidIdentification /
            stat.numberOfControlledPersonnel) *
          100
    );
    dataX6.push(statsCountry ? statsCountry.invalidIdentification : 0);

    const dataX7 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfPersonnelWithoutSafetyEquipment /
            stat.numberOfControlledPersonnel) *
          100
    );
    dataX7.push(statsCountry ? statsCountry.safetyEquipment : 0);

    const dataX8 = stats.map((stat) =>
      stat.numberOfControlledPersonnel === 0
        ? 0
        : (stat.numberOfPersonnelWithoutWorkPermit /
            stat.numberOfControlledPersonnel) *
          100
    );
    dataX8.push(statsCountry ? statsCountry.workPermit : 0);

    return {
      labelX,
      dataX1: dataX1.map((val) => Math.round(val)),
      dataX2: dataX2.map((val) => Math.round(val)),
      dataX3: dataX3.map((val) => Math.round(val)),
      dataX4: dataX4.map((val) => Math.round(val)),
      dataX5: dataX5.map((val) => Math.round(val)),
      dataX6: dataX6.map((val) => Math.round(val)),
      dataX7: dataX7.map((val) => Math.round(val)),
      dataX8: dataX8.map((val) => Math.round(val)),
    };
  }, [stats, statsCountry]);

  return data;
}
