import { S } from '../RedListedCompanies.styled';
import React from 'react';

export const Title: React.FC = () => {
  return (
    <S.HeaderContainer>
      <S.H3>Rödlistade bolag</S.H3>
    </S.HeaderContainer>
  );
};
