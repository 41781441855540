import { styled } from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const ErrorToast = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #e8a2a2;
  border-left: 6px solid #b00000;
  color: #b00000;
`;

const SuccessToast = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #d0f2d8;
  border-left: 6px solid #6fa577;
  color: #6fa577;
`;

const InfoToast = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #cfe2f3;
  border-left: 6px solid #096dd9;
  color: #096dd9;
`;

const WarningToast = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 2px;
  background-color: #fffbe6;
  border-left: 6px solid #faad14;
  color: #faad14;
`;

const H6 = styled.h6`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
`;

const P = styled.p`
  font-size: 14px;
  font-weight: 300;
`;

export const S = {
  ...UIElements,
  ErrorToast,
  SuccessToast,
  WarningToast,
  InfoToast,
  H6,
  P,
};
