import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const TemporaryFlexBorder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-left: 20px;
  border-left: 2px solid ${({ theme }) => theme.colors.white};
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const CustomText = styled(UIElements.D1)`
  font-size: 26px;
`;

export const S = {
  ...UIElements,
  TemporaryFlexBorder,
  Flex,
  CustomText,
};
