import { UIElements } from '@ansvar-sakerhet/ui';
import { Flex } from '@ansvar-sakerhet/ui/src/layout/Flex';
import { H5 } from '@ansvar-sakerhet/ui/src/typography/H5';
import { H6 } from '@ansvar-sakerhet/ui/src/typography/H6';
import { styled } from 'styled-components';

const MainContainer = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
`;

const StatsContainer = styled(Flex)`
  width: 100%;
`;

const MainTitleContainer = styled(Flex)`
  padding-bottom: ${({ theme }) => theme.spacing['l']};
`;

const NewsBorderContainer = styled.div`
  align-self: baseline;
  width: 100%;
  max-width: 850px;
  padding-top: ${({ theme }) => theme.spacing.m};
  padding-left: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.s};
  padding-right: ${({ theme }) => theme.spacing.m};
`;

const NewsGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray};
`;

const NewsLetterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing.s};
  padding-right: ${({ theme }) => theme.spacing.s};
  padding-top: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.m};
  background-color: ${({ theme }) => theme.colors.white};
`;

const TitleContainer = styled(Flex)`
  padding-bottom: ${({ theme }) => theme.spacing.s};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  padding-top: ${({ theme }) => theme.spacing.xs};
`;

const Subtitle = styled(H5)`
  font-weight: normal;
  padding-bottom: ${({ theme }) => theme.spacing.s};
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin-right: ${({ theme }) => theme.spacing.s};
  transition: transform 300ms;
`;

const Footer = styled(H6)`
  padding-top: ${({ theme }) => theme.spacing.m};
  padding-bottom: ${({ theme }) => theme.spacing.s};
  cursor: pointer;
`;

const OtherNewsImage = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${({ theme }) => theme.colors.gray};
`;

const OtherNewsSubtitle = styled.p`
  fontsize: 14px;
  lineheight: 21px;
  fontfamily: 'Helvetica Neue';
  fontweight: 300;
  opacity: 0.5;
  margin: 0;
  padding-top: ${({ theme }) => theme.spacing.s};
`;

const OtherNewsLetterContainer = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing.s};
  padding-bottom: ${({ theme }) => theme.spacing.s};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s};
  cursor: pointer;
`;

const NewsLetterText = styled.p`
  line-height: 21px;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing.m};

  li {
    margin-left: 1.5rem;
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
`;

export const S = {
  ...UIElements,
  StatsContainer,
  MainContainer,
  NewsBorderContainer,
  NewsGridContainer,
  TitleContainer,
  Subtitle,
  AnimatedIcon,
  Footer,
  OtherNewsImage,
  OtherNewsSubtitle,
  OtherNewsLetterContainer,
  MainTitleContainer,
  NewsLetterContainer,
  NewsLetterText,
};
