import { H6 } from '@ansvar-sakerhet/ui/src/typography/H6';
import React, { useState } from 'react';
import { Loading } from '../../components/Loading';
import {
  RedListedCompaniesSortControls,
  useGetRedlistedCompanies,
} from '../../traits/api/redListedCompanies/useRedListedCompanies';
import { S } from './RedListedCompanies.styled';
import { Table } from './components/Table';
import { Title } from './components/Title';
import { SearchInput } from '../../components/SearchInput';
import { Link } from 'react-router-dom';

export const RedListedCompanies: React.FC = () => {
  const [input, setInput] = useState('');
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<RedListedCompaniesSortControls>(
    RedListedCompaniesSortControls.CompanyNameAsc
  );
  const {
    data: companies,
    isLoading,
    error,
    count,
  } = useGetRedlistedCompanies({ page, sort, search: input });
  const searchWasEmpty = input.length > 0 && companies?.length === 0;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <H6>Någonting gick fel, försök igen senare.</H6>;
  }

  if (!companies) {
    return <H6> Det finns inga registrerade rödlistade bolag</H6>;
  }

  return (
    <S.RedListedContainer>
      <Title />
      <S.Flex $gap={12}>
        <Link to={'/rodlistade-bolag'} style={{ color: 'black' }}>
          <b>Ansvar & säkerhets rödlista</b>
        </Link>
        <Link style={{ color: 'black' }} to={'/rodlistat-sh'}>
          Svensk handels varningslista
        </Link>
      </S.Flex>

      <SearchInput
        input={input}
        setInput={setInput}
        placeholder="Sök efter organisationsnummer eller bolag"
      />
      <S.RelativeTableContainer>
        <Table
          companies={companies}
          setSort={setSort}
          sort={sort}
          page={page}
          setPage={setPage}
          numberOfPages={Math.ceil(count / 20)}
        />
        {searchWasEmpty && (
          <S.SearchErrorContainer>
            <S.SearchErrorTitle>Hittade inga företag</S.SearchErrorTitle>
            <S.SearchErrorSubTitle>
              Angav du rätt organisationsnummer eller företagsnamn?
            </S.SearchErrorSubTitle>
          </S.SearchErrorContainer>
        )}
      </S.RelativeTableContainer>
    </S.RedListedContainer>
  );
};
