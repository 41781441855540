import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { projectsKeys } from './projects.key';
import { toast } from '../../../components/Toast';

export function useOrderBackgroundControl(
  companyId: string,
  projectId: string
) {
  const getApi = useApi();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => {
      const api = await getApi();
      return await api.backgroundControlsOrderOrder({
        companyId,
        projectId,
      });
    },
    onError: () => toast.error('Något gick fel'),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectsKeys.all });
      toast.success('Bakgrundskontroll beställd');
    },
  });

  return mutation;
}
