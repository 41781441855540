import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './news.keys';

export function useGetNews(newsId: string | undefined) {
  const getApi = useApi();

  const getNews = async () => {
    if (!newsId) return null;

    const api = await getApi();

    const res = await api.newsFindOne(newsId);
    return res.data;
  };

  const query = useQuery({
    queryKey: keys.findOne(newsId),
    queryFn: getNews,
    keepPreviousData: true,
  });

  return {
    ...query,
  };
}
