import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Input = styled.input`
  padding: 16px 14px;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 300;
  &:focus {
    border: 1px solid #023e52;
  }
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Error = styled.div`
  padding: 4px;
`;

export const S = {
  ...UIElements,
  Wrapper,
  Input,
  Label,
  Error,
};
