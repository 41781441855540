import { useMemo } from 'react';
import { FindAllNewsResponse } from '@ansvar-sakerhet/api-client';

export const useOtherNewsletters = (
  chosenNews: FindAllNewsResponse | null,
  newsLetters: FindAllNewsResponse[] | undefined
) => {
  const other = useMemo(() => {
    const nonSorted =
      newsLetters?.filter(({ newsId }) => newsId !== chosenNews?.newsId) ?? [];

    const sorted = nonSorted.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return sorted;
  }, [newsLetters, chosenNews]);

  return other;
};
