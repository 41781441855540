import styled, { css, DefaultTheme } from 'styled-components';
import { addPxSuffix } from '../helpers/addPxSuffix';

type Props = {
  $flex?: number;
  $flexShrink?: number;
  $flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  $flexGrow?: number;
  $flexDir?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  $align?: keyof DefaultTheme['align'];
  $justify?: keyof DefaultTheme['justify'];
  $width?: string | number;
  $height?: string | number;
  $gap?: string | number;
};

export const Flex = styled.span<Props>`
  ${(props) => css`
    display: flex;
    flex-wrap: ${props.$flexWrap || 'nowrap'};
    gap: ${addPxSuffix(props.$gap || 0)};
    flex-grow: ${props.$flexGrow || 0};
    height: ${addPxSuffix(props.$height || 'auto')};
    width: ${addPxSuffix(props.$width || 'auto')};
    flex-direction: ${props.$flexDir || 'row'};
    align-items: ${props.$align
      ? props.theme.align[props.$align]
      : 'flex-start'};
    justify-content: ${props.$justify
      ? props.theme.justify[props.$justify]
      : 'flex-start'};
  `}
`;
