import React from 'react';
import { S } from './Header.styles';
import { useUser } from '../../traits/hooks/useUser';
import { useToggle } from '../../traits/hooks/useToggle';
import { ProfilePicture } from '../ProfilePicture';
import { HeaderDropdown } from '../HeaderDropdown';

export const Header: React.FC = () => {
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const { user } = useUser();

  return (
    <S.HeaderContainer>
      <S.HeaderInner>
        <S.IconWrapper to="/">
          <S.Icon src="HelmetGuy" width={38} />
        </S.IconWrapper>

        <S.ContentContainer>
          <S.ProfileInfoWrapper onClick={toggleDropdown}>
            <ProfilePicture user={user} width={24} height={24} />

            <S.B2 $top="xs" $weight={500}>
              {user.name}
            </S.B2>
          </S.ProfileInfoWrapper>
        </S.ContentContainer>

        <HeaderDropdown isOpen={isDropdownOpen} onClose={toggleDropdown} />
      </S.HeaderInner>
    </S.HeaderContainer>
  );
};
