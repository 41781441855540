import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';

export function useFindAllUsersFrameCompany() {
    const getApi = useApi();



    const getFrameCompaniesUsers = async () => {
        const api = await getApi();
        const res = await api.frameCompanyFindResponsibleUsers();
        return res;
    };

    const query = useQuery({
        queryFn: getFrameCompaniesUsers,
    });

    return {
        ...query,
        data: query.data?.data,

    };
}
