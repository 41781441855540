import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export const ProtectedRoute: React.FC = () => {
  const { user, isCustomerAdmin } = useAuth();
  const { pathname } = useLocation();

  if (!user) {
    return <Navigate to="/" replace state={{ redirectTo: pathname }} />;
  }

  if (!isCustomerAdmin) {
    return <Navigate to="/inte-tillåtet" replace />;
  }

  return <Outlet />;
};
