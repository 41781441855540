import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { companiesKeys } from './companies.key';

type Props = { page?: number; search?: string; take: number };

export function useGetAllCompaniesWithStatistics({
  page,
  search,
  take = 20,
}: Props) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getCompanies = async () => {
    const api = await getApi();
    const skip = page ? page * take : 0;

    const res = await api.companiesFindAllOnMyClientsWithStatistics(
      search,
      take,
      skip
    );
    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: companiesKeys.findAllWithStatistics({ page, search }),
    queryFn: getCompanies,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
