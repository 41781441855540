import Empty from './Empty.png';
import Confirmation from './Victory.png';
import Delete from './Delete.png';
import PdfCover from './PdfCover.png';
import FullLogo from './FullLogo.png';
import FullLogoDark from './FullLogoDark.png';

export const Images = {
  Confirmation,
  Delete,
  Empty,
  PdfCover,
  FullLogo,
  FullLogoDark,
};
