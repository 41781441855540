import React, { useState } from 'react';
import {
  SortControls,
  useGetMyProjects,
} from '../../../../traits/api/projects/useGetMyProjects';
import { ProjectsTable } from './components/ProjectsTable';
import { S } from './AllMyProjects.styles';
import { Loading } from '../../../../components/Loading';

export const AllMyProjects = () => {
  const [page, setPage] = useState(0);

  const [sort, setSort] = useState<SortControls>(SortControls.ProjectNameDesc);
  const { data: projects, count, isLoading } = useGetMyProjects(page, sort);

  if (isLoading || !projects) {
    return <Loading />;
  }

  return (
    <>
      <S.HeaderContainer>
        <S.H3>Projekt</S.H3>
      </S.HeaderContainer>
      <ProjectsTable
        projects={projects}
        setSort={setSort}
        sort={sort}
        setPage={setPage}
        page={page}
        numberOfPages={Math.ceil(count / 20)}
      />
    </>
  );
};
