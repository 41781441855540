import { useApi } from '../useApi';

export function useGetBGFile(controlId: string) {
  const getApi = useApi();

  const getControl = async () => {
    const api = await getApi();

    const res = await api.backgroundControlsGetControlFile(controlId);
    return res.data as any;
  };

  return getControl;
}
