import styled from 'styled-components';
import { addPxSuffix } from '@ansvar-sakerhet/ui/src/helpers/addPxSuffix';
import { UIElements } from '@ansvar-sakerhet/ui';

const ProfilePicture = styled.img<{
  height?: string | number;
  width?: string | number;
}>`
  width: ${({ width }) => addPxSuffix(width || '46px')};
  height: ${({ height }) => addPxSuffix(height || '46px')};
  object-fit: cover;
  border-radius: 9999px;
  aspect-ratio: 1 / 1;
`;

const NoProfilePicture = styled.div<{
  height?: string | number;
  width?: string | number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => addPxSuffix(width || '46px')};
  height: ${({ height }) => addPxSuffix(height || '46px')};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  aspect-ratio: 1 / 1;
`;

const DefaultAvatar = styled(UIElements.Icon)`
  color: ${({ theme }) => theme.colors.black};
`;

export const S = {
  ...UIElements,
  ProfilePicture,
  NoProfilePicture,
  DefaultAvatar,
};
