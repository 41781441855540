import { DefaultApiFactory } from '@ansvar-sakerhet/api-client';
import { useAuth } from '../hooks/useAuth';
import Axios from 'axios';
import { FLAVOR_PRODUCTION } from '../../config/constants';
import { Flavor } from '../../config/types';
import { getConfig } from '../../config/getConfig';

export const useApi = () => {
  const { accessToken, logout } = useAuth();

  const getApi = async () => {
    const axios = Axios.create();
    const config = await localStorage.getItem('APP_CONFIG');
    const { API_URL } = getConfig((config ?? FLAVOR_PRODUCTION) as Flavor);

    axios.interceptors.request.use(
      async (config) => {
        config.headers.Authorization = accessToken
          ? `Bearer ${accessToken}`
          : undefined;

        return config;
      },
      (err) => Promise.reject(err)
    );

    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response.status === 401) {
          logout();
        }

        return Promise.reject(err);
      }
    );

    const api = DefaultApiFactory(undefined, API_URL, axios);

    return api;
  };

  return getApi;
};
