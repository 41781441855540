import React from 'react';
import { ComparisonOptions } from '../../screens/ComparableStatistics';
import { ProjectFlow } from '../Flows/ProjectFlow';
import { SubcontractorsFlow } from '../Flows/SubcontractorsFlow';

export const DynamicFlow = ({
  flow,
}: {
  flow: keyof typeof ComparisonOptions;
}) => {
  switch (flow) {
    case 'project':
      return <ProjectFlow />;
    // case 'mainContractors':
    //   return <MainContractorsFlow />;
    // case 'siteManagers':
    //   return <SiteManagersFlow />;
    case 'subcontractors':
      return <SubcontractorsFlow />;
    case 'noneChosen':
    default:
      return <></>;
  }
};
