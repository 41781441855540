import styled from 'styled-components';

export const HR = styled.hr`
  border: none;

  margin-top: 1rem;
  margin-bottom: 1rem;

  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray};
`;
