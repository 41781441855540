import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { projectsKeys } from './projects.key';

export function useGetMyProjectsWithStatistics(
  page: number,
  search?: string,
  take = 20
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getControls = async () => {
    const api = await getApi();
    const skip = page * take;

    const res = await api.projectsFindAllMyProjectsWithStatistics(
      search,
      take,
      skip
    );
    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: projectsKeys.findMyProjectsWithStatistics({ page, search }),
    queryFn: getControls,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
