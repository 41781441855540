import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const PaginationButton = styled.button<{ $isActive?: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.secondary : theme.colors.white};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.white : theme.colors.secondary};
`;

const PaginationActionButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 43px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PaginationPrevButton = styled(PaginationActionButton)`
  padding: 0 16px 0 8px;
`;

const PaginationNextButton = styled(PaginationActionButton)`
  padding: 0 8px 0 16px;
`;

export const S = {
  ...UIElements,
  PaginationContainer,
  PaginationButton,
  PaginationPrevButton,
  PaginationNextButton,
};
