import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { toast } from '../../../components/Toast';
import { CreateFrameCompanyDto } from '@ansvar-sakerhet/api-client';

export function useCreateFrameCompany() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: CreateFrameCompanyDto) => {
    const api = await getApi();

    const res = await api.frameCompanyCreate(data);

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Företaget har lagts till');
    queryClient.invalidateQueries({ queryKey: keys.all });
  };

  const onError = () => {
    toast.error('Något gick fel');
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess,
    onError,
  });

  return mutation;
}
