import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../features/redListedCompanies/helpers/debounceSearch';

export function useGetAllFrameCompanies(
  take?: number,
  skip?: number,
  search?: string,
  tags?: string[],
  responsible?: string | null,

) {
  const getApi = useApi();

  const getFrameCompanies = async () => {
    const api = await getApi();

    const res = await api.frameCompanyFindAll(take, skip, search, responsible && responsible !== 'no_responsible' ? responsible : undefined, tags && tags?.length > 0 ? tags : undefined);

    return res;
  };

  const query = useQuery({
    queryKey: keys.findAll(take, skip, search, responsible ?? undefined, tags),
    queryFn: getFrameCompanies,
  });


  return {
    ...query,
    data: query.data?.data,
    count: query.data?.headers['x-total-count'],
  };
}
