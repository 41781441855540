import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.l};
`;

export const S = {
  HeaderContainer,
  ...UIElements,
};
