import { FindAllNewsResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';
import { useGetNews } from '../../../traits/api/news/useGetNews';

/**
 * Returns the specified news dictated by the newsId,
 * or the latest news in the specified newLetters if no
 * newsId is provided or the specified News does not exist
 */

export const useNewsLetter = (
  newsId: string | undefined,
  newsLetters: FindAllNewsResponse[] | undefined
) => {
  const { data: chosenNews, isLoading } = useGetNews(newsId);

  const news = useMemo(() => {
    if (chosenNews) return chosenNews;

    if (!newsLetters) return null;

    return newsLetters.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )[0];
  }, [newsLetters, chosenNews]);

  return { news, isLoading };
};
