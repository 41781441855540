import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

const FrameCompanies = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const BackButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const FrameCompany = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  width: 100%;
`;

const FrameCompanyTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 500;
`;

const FrameCompanyDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const Form = styled.form`
  max-width: 400px;
`;

const Wrapper = styled(AlertDialog.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
  height: 472px;
  min-width: 472px;
  max-width: 800px;
  width: 48vw;
  padding: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  max-width: 336px;
  gap: 16px;
`;

const CancelButton = styled(AlertDialog.Cancel)`
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Overlay = styled(AlertDialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 200;
  display: grid;
  position: fixed;
  place-items: center;
  inset: 0;
`;

export const S = {
  BackButton,
  Form,
  FrameCompanies,
  FrameCompany,
  FrameCompanyTitle,
  FrameCompanyDescription,
  Overlay,
  Wrapper,
  ContentContainer,
  ButtonContainer,
  CancelButton,
  ...UIElements,

};
