import React, { useState } from 'react';
import { S } from './ComparableStatistics.styles';
import { Select } from '../../../../components/Select';
import { DynamicFlow } from '../../components/DynamicFlow';

export const ComparisonOptions = {
  noneChosen: 'Välj',
  project: 'Projekt',
  // siteManagers: 'Platschefer',
  // mainContractors: 'Huvudentreprenörer',
  subcontractors: 'Underentreprenörer',
} as const;

export const ComparableStatistics = () => {
  const [comparisonOption, setComparisonOption] =
    useState<keyof typeof ComparisonOptions>('noneChosen');

  return (
    <S.Container>
      <S.H3>Statistik</S.H3>
      <S.Margin $top="xl" />
      <Select
        label="Välj vad du vill jämföra"
        onChange={({ target }) =>
          setComparisonOption(target.value as keyof typeof ComparisonOptions)
        }
      >
        {Object.entries(ComparisonOptions).map(([key, value]) => {
          return (
            <option key={key} value={key}>
              {value}
            </option>
          );
        })}
      </Select>

      <S.Margin $top="xl" />

      <DynamicFlow flow={comparisonOption} />
    </S.Container>
  );
};
