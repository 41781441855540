import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../features/redListedCompanies/helpers/debounceSearch';
import { useApi } from '../useApi';
import { redListedCompanyKeys } from './redListedCompanies.key';

export enum RedListedCompaniesSortControls {
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  RegistrationNumberDesc = 'registrationNumber_DESC',
  RegistrationNumberAsc = 'registrationNumber_ASC',
  DateDesc = 'date_DESC',
  DateAsc = 'date_ASC',
}

interface RedListedCompaniesHook {
  sort?: RedListedCompaniesSortControls;
  page?: number;
  search?: string;
}

export function useGetRedlistedCompanies({
  search,
  page = 0,
  sort = RedListedCompaniesSortControls.CompanyNameAsc,
}: RedListedCompaniesHook) {
  const getApi = useApi();
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [count, setCount] = useState(0);
  const debounce = useDebounce();
  const getRedlistedCompanies = async () => {
    const api = await getApi();
    const take = 20;
    const skip = page * take;

    const res = await api.redListedCompaniesFindAllRedListedCompanies(
      search,
      take,
      skip,
      sort
    );
    setCount(Number(res.headers['x-total-count']));

    return res.data;
  };

  useEffect(() => {
    if (typeof search === 'string') {
      debounce(() => setDebouncedSearch(search), 300);
    }
  }, [search]);

  const query = useQuery({
    queryKey: redListedCompanyKeys.findAll({
      page,
      sort,
      search: debouncedSearch,
    }),
    queryFn: getRedlistedCompanies,
    keepPreviousData: true,
  });

  return { ...query, count };
}
