import React, { SVGProps, createElement } from 'react';
import { IconType } from './IconType';
import { useTheme } from 'styled-components';
import { defaultTheme } from '../core/theme';

interface Props extends SVGProps<SVGSVGElement> {
  src: keyof typeof IconType;
}

export const Icon: React.FC<Props> = (props) => {
  const theme = useTheme();
  const icon = IconType[props.src];
  const { color, ...rest } = props;

  const isColor = (
    color: string
  ): color is keyof (typeof defaultTheme)['colors'] =>
    Object.keys(theme.colors).indexOf(color) !== -1;

  const iconProps = {
    ...rest,
    color: color ? (isColor(color) ? theme.colors[color] : color) : 'black',
  };

  return createElement(icon.ReactComponent, iconProps);
};
