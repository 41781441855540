import { FindMyProjectWithControls } from '@ansvar-sakerhet/api-client';
import { SortControls } from '../hooks/useControlsTableData';
import { getAmountOfDeviations } from './getAmountOfDeviations';

export function sortControls(
  controls: FindMyProjectWithControls['controls'],
  sort: SortControls
) {
  switch (sort) {
    case SortControls.DateAsc:
      return controls.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    case SortControls.DateDesc:
      return controls.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
    case SortControls.SiteManagerAsc:
      return controls.sort((a, b) =>
        a.siteManager.localeCompare(b.siteManager)
      );
    case SortControls.SiteManagerDesc:
      return controls.sort((a, b) =>
        b.siteManager.localeCompare(a.siteManager)
      );
    case SortControls.AmountOfDeviationsAsc:
      return controls.sort((a, b) => {
        const aAmount = getAmountOfDeviations(a);
        const bAmount = getAmountOfDeviations(b);

        return aAmount - bAmount;
      });
    case SortControls.AmountOfDeviationsDesc:
      return controls.sort((a, b) => {
        const aAmount = getAmountOfDeviations(a);
        const bAmount = getAmountOfDeviations(b);

        return bAmount - aAmount;
      });
    default:
      return controls;
  }
}
