import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';

type Options = {
  onPostImageSuccess?: () => void;
  onPostImageError?: (error: unknown) => void;
  frameCompanyId: string;
  type: string;
};

export function useUploadFrameCompanyFile(options: Options) {
  const getApi = useApi();

  const postFile = async (image: File) => {
    const api = await getApi();

    const formData = new FormData();
    formData.append('file', image);
    formData.append('type', options.type);
    formData.append('frameCompanyId', options.frameCompanyId);

    const fileResponse = await api.frameCompanyUpload({
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return fileResponse.data;
  };

  const onSuccess = () => {
    options?.onPostImageSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel, bilden gick inte att spara');
    options?.onPostImageError?.(error);
  };

  const mutation = useMutation(postFile, {
    onSuccess,
    onError,
  });

  return mutation;
}
