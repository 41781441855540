import styled from 'styled-components';

export const TBodyRow = styled.tr<{ $underline?: boolean }>`
  ${({ $underline }) => $underline && `border-bottom: 1.5px solid #d3aa3b;`}

  &:last-child {
    border-bottom: none;
  }
  &:nth-child(even) {
    background: #f1ead6;
  }
`;
