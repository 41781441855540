import {
  BackgroundControlWithFilesRiskEnum,
  CompanyWithBackgroundControl,
} from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';

export const useSubcontractorsStatisticsTableData = (
  subcontractors: CompanyWithBackgroundControl[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Underentreprenörer statistik',
        accessor: 'statisticsDescription',
      },
      {
        Header: 'Antal',
        accessor: 'amount',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    const controls = subcontractors.flatMap(
      ({ backgroundControls }) => backgroundControls
    );
    const noRisk = controls.filter(
      ({ risk }) => risk === BackgroundControlWithFilesRiskEnum.NoRisk
    );
    const lesserRisk = controls.filter(
      ({ risk }) => risk === BackgroundControlWithFilesRiskEnum.LesserRisk
    );
    const mediumHighRisk = controls.filter(
      ({ risk }) => risk === BackgroundControlWithFilesRiskEnum.MediumHighRisk
    );
    const highRisk = controls.filter(
      ({ risk }) => risk === BackgroundControlWithFilesRiskEnum.HighRisk
    );

    return [
      {
        statisticsDescription: 'Inga risker identifierade',
        amount: `${noRisk.length} st`,
      },
      {
        statisticsDescription: 'Mindre väsentliga risker',
        amount: `${lesserRisk.length} st`,
      },
      {
        statisticsDescription: 'Medel-hög risk',
        amount: `${mediumHighRisk.length} st`,
      },
      {
        statisticsDescription: 'Hög risk',
        amount: `${highRisk.length} st`,
      },
    ];
  }, [subcontractors]);

  return { columns, data };
};
