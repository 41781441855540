import React from 'react';
import { S } from './ProfilePicture.styles';
import { User } from '@auth0/auth0-react';

type Props = {
  user: User;
  height?: number | string;
  width?: number | string;
};

export const ProfilePicture: React.FC<Props> = (props) => {
  return props.user.picture ? (
    <S.ProfilePicture
      src={props.user.picture}
      alt="Profil bild"
      height={props.height}
      width={props.width}
    />
  ) : (
    <S.NoProfilePicture height={props.height} width={props.width}>
      <S.DefaultAvatar src="UserCircle" />
    </S.NoProfilePicture>
  );
};
