import styled from 'styled-components';

export const TBodyRow = styled.tr`
  position: relative;
  height: 58px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  &:nth-child(even) {
    background-color: #fbf6f3;
  }

  ${({ onClick }) => !!onClick && 'cursor: pointer;'}
`;
