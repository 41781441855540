import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { CreateDeviationCounterMeasureDto } from '@ansvar-sakerhet/api-client';
import { toast } from '../../../components/Toast';

export function useCreateDeviationCounterMeasure() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: CreateDeviationCounterMeasureDto) => {
    const api = await getApi();
    const formattedData: CreateDeviationCounterMeasureDto = {
      ...data,
      fixedAt: new Date(data.fixedAt).toISOString(),
    };

    const res = await api.deviationCounterMeasuresCreateOnMyControl(
      formattedData
    );
    await queryClient.invalidateQueries();

    return res.data;
  };

  const query = useMutation({
    mutationFn,
    onSuccess: () => toast.success('Avvikelse åtgärdad'),
    onError: () => toast.error('Kunde inte åtgärda avvikelse'),
  });

  return {
    ...query,
  };
}
