import { Icon } from '@ansvar-sakerhet/ui/src/icons/Icon';
import React from 'react';
import { S } from './SearchInput.styles';

interface SearchProps {
  placeholder: string;
  input: string;
  setInput: (input: string) => void;
}

export const SearchInput: React.FC<SearchProps> = ({
  input,
  setInput,
  placeholder,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  return (
    <S.SearchContainer>
      <Icon src="Search" height={16} width={16} />
      <S.SearchInput
        placeholder={placeholder}
        onChange={onChange}
        value={input}
        autoFocus
      />
    </S.SearchContainer>
  );
};
