const all = ['controls'] as const;

const findOne = (inspectionId: string) => {
  return [...all, inspectionId] as const;
};

const findStatistics = (inspectionId: string) => {
  return [...findOne(inspectionId), 'statistics'] as const;
};

export const controlKeys = {
  all,
  findOne,
  findStatistics,
};
