import React from 'react';
import { S } from '../screens/News/News.styles';
import { FindAllNewsResponse } from '@ansvar-sakerhet/api-client';
import { markdownParser } from '../../../traits/utils/markdownParser';

export const NewsLetter = ({ news }: { news: FindAllNewsResponse | null }) => {
  if (!news) {
    return (
      <S.NewsBorderContainer>
        <S.NewsLetterContainer>
          <S.Subtitle>Inga nyheter att visa</S.Subtitle>
        </S.NewsLetterContainer>
      </S.NewsBorderContainer>
    );
  }

  return (
    <S.NewsBorderContainer>
      <S.TitleContainer $justify="between">
        <S.Subtitle>{news.header}</S.Subtitle>
      </S.TitleContainer>
      <S.NewsLetterContainer>
        <S.NewsLetterText
          dangerouslySetInnerHTML={{ __html: markdownParser(news.content) }}
        ></S.NewsLetterText>
      </S.NewsLetterContainer>
    </S.NewsBorderContainer>
  );
};
