import { styled } from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const S = {
  LoadingContainer,
};
