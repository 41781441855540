import React from 'react';
import * as S from './Spinner.style';
import { DefaultTheme, useTheme } from 'styled-components';

type Props = {
  width?: number;
  height?: number;
  color?: keyof DefaultTheme['colors'];
};

export const Spinner: React.FC<Props> = (props) => {
  const theme = useTheme();

  const color = props.color ? theme.colors[props.color] : theme.colors.black;

  return (
    <S.Spinner
      src="Spinner"
      color={color}
      width={props.width || 24}
      height={props.height || 24}
    />
  );
};
