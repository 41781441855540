import { Flex } from '@ansvar-sakerhet/ui/src/layout/Flex';
import React from 'react';
import { FaqTableRow } from './components/FaqTableRow';
import { useGetFaq } from '../../traits/api/faq/useGetFaq';
import { H6 } from '@ansvar-sakerhet/ui/src/typography/H6';
import { S } from './Faq.styles';
export const Faq: React.FC = () => {
  const { customerPortalFaq } = useGetFaq();

  if (!customerPortalFaq || customerPortalFaq.length === 0) {
    return <H6>Någonting gick fel, försök igen senare.</H6>;
  }

  const formatFaq = customerPortalFaq.map((faq) => {
    return {
      id: faq.faqId,
      title: faq.question,
      text: faq.answer,
    };
  });

  return (
    <Flex $flexDir="column" $align="center">
      <S.FaqSubContainer $flexDir="column">
        <S.Title>FAQ</S.Title>
        {formatFaq?.map((faq) => {
          return <FaqTableRow key={faq?.id} {...faq} />;
        })}
      </S.FaqSubContainer>
    </Flex>
  );
};
