import { H6 } from '@ansvar-sakerhet/ui/src/typography/H6';
import { S } from '../Faq.styles';
import React, { useState } from 'react';
import { TextRenderer } from './TextRenderer';

interface FaqTableRowProps {
  title: string;
  text: string;
}

export const FaqTableRow: React.FC<FaqTableRowProps> = ({ text, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onPressRow = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <S.Container>
      <S.Trigger type="button" onClick={onPressRow} $isExpanded={isExpanded}>
        <H6>{title}</H6>
        <S.AnimatedIcon src="ChevronDown" />
      </S.Trigger>
      {isExpanded && (
        <S.Content>
          <TextRenderer text={text} />
        </S.Content>
      )}
    </S.Container>
  );
};
