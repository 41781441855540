import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { S } from './OrderControlDialog.styles';
import { FindAllFrameCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { createFrameCompanyBackgroundOrder } from '../../../../../../../../traits/api/frameCompany/createFrameCompanyBackgroundOrder';

type Props = {
  company: FindAllFrameCompaniesResponse;
  onCancel: () => void;
};

export const OrderControlDialog: React.FC<Props> = (props) => {
  const order = createFrameCompanyBackgroundOrder();
  const { BackgroundControlOrder } = props.company;
  const hasOrdered = BackgroundControlOrder.length > 0;

  const orderBg = async () => {
    if (hasOrdered) return;
    await order.mutateAsync({
      frameCompanyId: props.company.frameCompanyId,
      clientId: props.company.clientId,
    });
    props.onCancel();
  };

  return (
    <AlertDialog.Portal>
      <S.Overlay>
        <S.Wrapper>
          <S.CancelButton onClick={props.onCancel}>
            <S.Icon src="Close" width={24} height={24} />
          </S.CancelButton>

          <S.ContentContainer>
            <img src={Images.Confirmation} width={295} height={215} />

            <AlertDialog.Title asChild>
              <S.H5>
                Är du säker på att du vill beställa en bakgrundskontroll för{' '}
                företaget {props.company.name}?
              </S.H5>
            </AlertDialog.Title>

            <S.ButtonContainer>
              <AlertDialog.Cancel asChild>
                <S.Button $outlined onClick={props.onCancel}>
                  Avbryt
                </S.Button>
              </AlertDialog.Cancel>

              <AlertDialog.Action asChild>
                <S.Button onClick={orderBg} disabled={order.isLoading}>
                  Ja
                </S.Button>
              </AlertDialog.Action>
            </S.ButtonContainer>
          </S.ContentContainer>
        </S.Wrapper>
      </S.Overlay>
    </AlertDialog.Portal>
  );
};
