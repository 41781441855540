import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';

type Options = {
  onPostImageSuccess?: () => void;
  onPostImageError?: (error: unknown) => void;
  frameCompanyId: string;
  type: string;
};

export function useDeleteFrameCompanyFile(options: Options) {
  const getApi = useApi();

  const postFile = async () => {
    const api = await getApi();

    const fileResponse = await api.frameCompanyDeleteUpload({
        frameCompanyId: options.frameCompanyId,
        type: options.type,
    });

    return fileResponse.data;
  };

  const onSuccess = () => {
    options?.onPostImageSuccess?.();
  };

  const onError = (error: unknown) => {
    toast.error('Något gick fel');
    options?.onPostImageError?.(error);
  };

  const mutation = useMutation(postFile, {
    onSuccess,
    onError,
  });

  return mutation;
}
