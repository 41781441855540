import React from 'react';
import { S } from './Main.styles';
import { SideNavigationBar } from '../SideNavigationBar';

export const Main: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <S.MainContainer>
      <SideNavigationBar />
      <S.DynamicContent>{props.children}</S.DynamicContent>
    </S.MainContainer>
  );
};
