import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import {
  BackgroundControlWithFiles,
  BackgroundControlWithFilesRiskEnum,
  CompanyWithBackgroundControl,
} from '@ansvar-sakerhet/api-client';
import { sortContractors } from '../utils/sortContractors';
import { format } from 'date-fns';
import { DownloadReportButton } from '../components/DownloadReportButton';
import { OrderBackgroundControlButton } from '../components/OrderBackgroundControlButton';
import { findLatestReport } from '../utils/findLatestReport';

export enum SortControls {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RegistrationNumberAsc = 'registrationNumber_ASC',
  RegistrationNumberDesc = 'registrationNumber_DESC',
  LatestBackgroundControlAsc = 'latestBackgroundControl_ASC',
  LatestBackgroundControlDesc = 'latestBackgroundControl_DESC',
}

export const useSubcontractorsTableData = (
  subcontractors: CompanyWithBackgroundControl[],
  projectId: string
) => {
  const [sort, setSort] = useState(SortControls.NameAsc);
  const sortedControls = useMemo(() => {
    return [...sortContractors(subcontractors, sort)];
  }, [subcontractors, sort]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
        onClick: () => {
          if (sort === SortControls.NameAsc) {
            return setSort(SortControls.NameDesc);
          }

          return setSort(SortControls.NameAsc);
        },
        iconSrc: () =>
          sort === SortControls.NameAsc ? 'ArrowDropUp' : 'ArrowDropDown',
      },
      {
        Header: 'Org.nr',
        accessor: 'registrationNumber',
        onClick: () => {
          if (sort === SortControls.RegistrationNumberAsc) {
            return setSort(SortControls.RegistrationNumberDesc);
          }

          return setSort(SortControls.RegistrationNumberAsc);
        },
        iconSrc: () =>
          sort === SortControls.RegistrationNumberAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Senaste granskning',
        accessor: 'latestBackgroundControl',
        onClick: () => {
          if (sort === SortControls.LatestBackgroundControlAsc) {
            return setSort(SortControls.LatestBackgroundControlDesc);
          }

          return setSort(SortControls.LatestBackgroundControlAsc);
        },
        iconSrc: () =>
          sort === SortControls.LatestBackgroundControlAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Bortvalt',
        accessor: 'optedOut',
        onClick: null,
        iconSrc: null,
      },
      {
        Header: 'Riskbedömning',
        accessor: 'risk',
        onClick: null,
        iconSrc: null,
      },
      { Header: 'Rapport', accessor: 'report', onClick: null, iconSrc: null },
    ] as const;
  }, [sort]);

  const data = useMemo(() => {
    return sortedControls.map((contractor) => {
      const latestBackgroundControl = getLatestBackgroundControl(contractor);
      const latestBackgroundControlDate = latestBackgroundControl
        ? format(new Date(latestBackgroundControl.createdAt), 'yyyy/MM/dd')
        : '-';

      return {
        name: contractor.name,
        registrationNumber: contractor.registrationNumber,
        latestBackgroundControl: latestBackgroundControlDate,
        optedOut: optedOutTranslation(latestBackgroundControl?.optedOut) ?? '-',
        risk: AcceptanceTranslation[latestBackgroundControl?.risk] ?? '-',
        report: getDynamicReportButton(
          latestBackgroundControl,
          contractor,
          projectId
        ),
      };
    });
  }, [sortedControls]);

  return { columns, data };
};

const optedOutTranslation = (optedOut: boolean) => (optedOut ? 'Ja' : 'Nej');

const AcceptanceTranslation: Record<
  BackgroundControlWithFilesRiskEnum,
  string
> = {
  NoRisk: 'Inga risker identifierade.',
  LesserRisk: 'Avvikelser identifierade, OK att samarbeta.',
  MediumHighRisk: 'Avvikelser identifierade, åtgärder föreslås.',
  HighRisk:
    'Väsentliga risker. Avrådan från att anlita företaget eller åtgärda avvikelser.',
};

function getLatestBackgroundControl(company: CompanyWithBackgroundControl) {
  return company.backgroundControls.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}

function getDynamicReportButton(
  backgroundControl: BackgroundControlWithFiles | undefined,
  contractor: CompanyWithBackgroundControl,
  projectId: string
) {
  switch (backgroundControl?.risk) {
    case BackgroundControlWithFilesRiskEnum.NoRisk:
    case BackgroundControlWithFilesRiskEnum.LesserRisk:
    case BackgroundControlWithFilesRiskEnum.MediumHighRisk:
    case BackgroundControlWithFilesRiskEnum.HighRisk:
      const downloadUrl =
        findLatestReport(backgroundControl)?.backgroundControlId;

      return (
        <DownloadReportButton
          risk={backgroundControl.risk}
          downloadUrl={downloadUrl}
        />
      );
    default:
      return (
        <OrderBackgroundControlButton
          company={contractor}
          projectId={projectId}
        />
      );
  }
}
