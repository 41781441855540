import { Theme } from '../core/types';
import { addPxSuffix } from '../helpers/addPxSuffix';
import { Margin } from '../layout/Margin';

export interface Typography extends Margin {
  $width?: string | number;
  $height?: string | number;
  $color?: keyof Theme['colors'];
  $align?: 'left' | 'center' | 'right';
  $decoration?:
    | 'none'
    | 'underline'
    | 'line-through'
    | 'underline line-through';
  $weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  $lineHeight?: string | number;
  $zIndex?: number;
}

export function styledParagraph(props: Typography & { theme: Theme }) {
  return `
    font-family: Helvetica Neue;
    width: ${addPxSuffix(props.$width || 'auto')};
    height: ${addPxSuffix(props.$height || 'auto')};
    font-weight: ${props.$weight ?? 300};
    text-align: ${props.$align || 'left'};
    text-decoration-line: ${props.$decoration || 'none'};
    color: ${props.$color ? props.theme.colors[props.$color] : 'black'};
    line-height: ${addPxSuffix(props.$lineHeight || 'auto')};
    z-index: ${props.$zIndex || 0};
  `;
}

export function styledHeader(props: Typography & { theme: Theme }) {
  return `
    font-family: Helvetica Neue;
    width: ${addPxSuffix(props.$width || 'auto')};
    height: ${addPxSuffix(props.$height || 'auto')};
    font-weight: ${props.$weight ?? 500};
    text-align: ${props.$align || 'left'};
    text-decoration-line: ${props.$decoration || 'none'};
    color: ${props.$color ? props.theme.colors[props.$color] : 'black'};
    line-height: ${addPxSuffix(props.$lineHeight || 'auto')};
    z-index: ${props.$zIndex || 0};
  `;
}
