import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { projectsKeys } from './projects.key';

export enum SortControls {
  ProjectNameAsc = 'projectName_ASC',
  ProjectNameDesc = 'projectName_DESC',
  MainContractorDesc = 'mainContractor_DESC',
  MainContractorAsc = 'mainContractor_ASC',
  SiteManagerDesc = 'siteManager_DESC',
  SiteManagerAsc = 'siteManager_ASC',
}

export function useGetMyProjects(
  page: number,
  sort: SortControls,
  search?: string,
  take = 20
) {
  const getApi = useApi();
  const [count, setCount] = useState(0);

  const getControls = async () => {
    const api = await getApi();
    const skip = page * take;

    const res = await api.projectsFindAllMyProjects(search, take, skip, sort);
    setCount(Number(res.headers['x-total-count']));
    return res.data;
  };

  const query = useQuery({
    queryKey: projectsKeys.findMyProjects({ page, sort, search }),
    queryFn: getControls,
    keepPreviousData: true,
  });

  return {
    ...query,
    count,
  };
}
