import { RedListedCompaniesSortControls } from './useRedListedCompanies';

const redlistedCompanies = ['redListedCompanies'] as const;

const findOne = (registrationNumberOrCompanyId: string) => {
  return [...redlistedCompanies, registrationNumberOrCompanyId] as const;
};

const findAll = (filters?: {
  page: number;
  sort: RedListedCompaniesSortControls;
  search?: string;
}) => {
  return [...redlistedCompanies, 'all', { ...filters }] as const;
};

export const redListedCompanyKeys = {
  redlistedCompanies,
  findOne,
  findAll,
};
