import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';

export function useGetCompanyStats() {
  const getApi = useApi();

  const getStats = async (companies: string[]) => {
    const api = await getApi();
    return await api.companyStatisticsFindCompanies({ companies });
  };

  const mutation = useMutation(getStats, {});

  return mutation;
}
