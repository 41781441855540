import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { toast } from '../../../components/Toast';

export function useDeleteFrameCompany() {
    const getApi = useApi();
    const queryClient = useQueryClient();

    const mutationFn = async (companyId: string) => {
        const api = await getApi();

        const res = await api.frameCompanyDelete(companyId);

        return res.data;
    };

    const onSuccess = () => {
        toast.success('Företaget har raderats');
        queryClient.invalidateQueries({ queryKey: keys.all });
    };

    const onError = () => {
        toast.error('Något gick fel');
    };

    const mutation = useMutation({
        mutationFn,
        onSuccess,
        onError,
    });

    return mutation;
}
