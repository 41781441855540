import React, { useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { S } from './MultiSelect.styles';
import { Checkbox } from '../Checkbox';
import { SearchInput } from '../SearchInput';

export type Option = { key: string; label: string };

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: Option[];
  chosenOptions: Option[];
  setChosenOptions: (options: Option[]) => void;
  noResultsText?: string;
  searchPlaceholder?: string;
  error?: FieldError | undefined;
  width?: number;
}

export const MultiSelect = (props: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const [show, setShow] = useState(false);
  const searchedOptions = useMemo(() => {
    if (!searchInput || searchInput === '') return props.options;

    return props.options.filter(({ label }) =>
      label.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [searchInput, props.options]);

  const isChosen = (option: Option) =>
    !!props.chosenOptions.find(({ key }) => key === option.key);

  const removeChosen = (option: Option) => {
    const withoutChosen = props.chosenOptions.filter(
      ({ key }) => key !== option.key
    );
    return props.setChosenOptions(withoutChosen);
  };

  const onCheck = (option: Option) => {
    const chosen = isChosen(option);

    if (chosen) {
      return removeChosen(option);
    }

    return props.setChosenOptions([...props.chosenOptions, option]);
  };

  return (
    <div style={{ width: props.width }}>
      <S.Wrapper>
        <S.Label>{props.label}</S.Label>
        <div>
          <S.MultiSelect onClick={() => setShow(!show)}>
            {props.chosenOptions.map(({ key, label }) => (
              <S.ChosenOption
                onClick={(e) => {
                  e.stopPropagation();
                  removeChosen({ key, label });
                }}
                key={key}
              >
                {label}
                <S.Margin $left="s" />
                <S.Icon width={16} src="Close" />
              </S.ChosenOption>
            ))}
            <S.SelectIcon $rotated={show} src="ChevronUp" />
          </S.MultiSelect>
          <S.SelectBox $show={show}>
            {props.searchPlaceholder && (
              <SearchInput
                placeholder={props.searchPlaceholder}
                input={searchInput}
                setInput={setSearchInput}
              />
            )}
            {searchedOptions.map(({ key, label }) => (
              <S.MultiOption key={key}>
                <Checkbox
                  checked={isChosen({ key, label })}
                  onChange={() => onCheck({ key, label })}
                  label={label}
                />
              </S.MultiOption>
            ))}
            {searchedOptions.length === 0 && (
              <S.MultiOption>
                <S.Margin $top="s" />
                {props.noResultsText ?? 'Ingen data att visa'}
              </S.MultiOption>
            )}
          </S.SelectBox>
        </div>
      </S.Wrapper>
      {props.error && (
        <S.Error>
          <S.B3 $color="error">{props.error.message}</S.B3>
        </S.Error>
      )}
    </div>
  );
};
