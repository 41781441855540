import styled from 'styled-components';
import { styledParagraph, Typography } from './BaseTypes';
import { styledMargin } from '../layout/Margin';

export const D1 = styled.p<Typography>`
  ${styledParagraph}
  ${styledMargin}
  font-size: 20px;
  text-transform: uppercase;
`;
