import { useMemo } from 'react';
import { FindAllCompaniesResponse } from '@ansvar-sakerhet/api-client';

export const useContractors = (contractors: FindAllCompaniesResponse[]) => {
  const mainContractors = useMemo(() => {
    return (
      contractors.filter(
        ({ mainContractorOnControl }) => mainContractorOnControl.length > 0
      ) ?? []
    );
  }, [contractors]);

  const subcontractors = useMemo(() => {
    const nonUniqueSubcontractors = contractors.filter(
      ({ registrationNumber: regNrToFind }) => {
        const companyAsMainContractor = mainContractors.find(
          ({ registrationNumber }) => registrationNumber === regNrToFind
        );

        return !companyAsMainContractor;
      }
    );

    return nonUniqueSubcontractors;
  }, [contractors]);

  return { subcontractors, mainContractors };
};
