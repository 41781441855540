import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from '../../../components/Layout';

export const LayoutRoute: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
