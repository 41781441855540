import React from 'react';
import { S } from '../screens/News/News.styles';
import { H6 } from '@ansvar-sakerhet/ui/src/typography/H6';
import { Flex } from '@ansvar-sakerhet/ui/src/layout/Flex';
import { FindAllNewsResponse } from '@ansvar-sakerhet/api-client';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export const OtherNews = ({
  news,
  onShowMore,
  canShowMore,
}: {
  news: FindAllNewsResponse[];
  onShowMore: () => void;
  canShowMore: boolean;
}) => {
  const navigate = useNavigate();

  if (news.length === 0) {
    return <></>;
  }

  return (
    <S.NewsBorderContainer>
      <S.TitleContainer>
        <S.Subtitle>Andra nyhetsbrev</S.Subtitle>
      </S.TitleContainer>
      {news.map((letter) => {
        return (
          <S.OtherNewsLetterContainer
            key={letter.newsId}
            onClick={() => navigate(`/nyheter/${letter.newsId}`)}
          >
            <Flex $flexDir="column">
              <S.Margin $top="s" />
              <H6>{letter.header}</H6>
              <S.OtherNewsSubtitle>
                {format(new Date(letter.createdAt), 'yyyy-MM-dd')}
              </S.OtherNewsSubtitle>
              <S.Margin $top="s" />
            </Flex>
          </S.OtherNewsLetterContainer>
        );
      })}
      {news.length !== 0 && canShowMore && (
        <S.Footer onClick={onShowMore} $align="center">
          Visa fler
        </S.Footer>
      )}
    </S.NewsBorderContainer>
  );
};
