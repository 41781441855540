import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const ShowBarChartContainer = styled.div`
  height: 100%;
  min-height: 400px;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1350px) {
    width: calc(50% - 5px);
  }
`;

export const S = { ...UIElements, ShowBarChartContainer };
