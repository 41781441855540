import { createContext, useContext, useEffect, useState } from 'react';
import { Config, Flavor } from '../types';
import { getConfig } from '../getConfig';
import {
  FLAVOR_DEVELOPMENT,
  FLAVOR_PRODUCTION,
  FLAVOR_STAGING,
} from '../constants';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ConfigProvider>.');
};

type State = {
  config: Config;
  activeConfig: Flavor;
  setDevelopmentConfig: () => void;
  setProductionConfig: () => void;
  setStagingConfig: () => void;
};

const initialState: State = {
  config: getConfig(FLAVOR_PRODUCTION),
  activeConfig: FLAVOR_PRODUCTION,
  setDevelopmentConfig: stub,
  setProductionConfig: stub,
  setStagingConfig: stub,
};

export const AppConfigContext = createContext(initialState);

export const useAppConfigContext = () => useContext(AppConfigContext);

export const useAppConfig = () => {
  const [activeConfig, setActiveConfig] = useState<Flavor>(FLAVOR_PRODUCTION);

  useEffect(() => {
    const config = localStorage.getItem('APP_CONFIG');
    if (!config) return;
    setActiveConfig(config as Flavor);
  }, []);

  function setDevelopmentConfig() {
    localStorage.setItem('APP_CONFIG', FLAVOR_DEVELOPMENT);
    setActiveConfig(FLAVOR_DEVELOPMENT);
  }

  function setProductionConfig() {
    localStorage.setItem('APP_CONFIG', FLAVOR_PRODUCTION);
    setActiveConfig(FLAVOR_PRODUCTION);
  }

  function setStagingConfig() {
    localStorage.setItem('APP_CONFIG', FLAVOR_STAGING);
    setActiveConfig(FLAVOR_STAGING);
  }

  const config = getConfig(activeConfig);

  return {
    config,
    activeConfig,
    setDevelopmentConfig,
    setProductionConfig,
    setStagingConfig,
  };
};
