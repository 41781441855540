import { useMemo } from 'react';
import { FindAllMyProjectsResponse } from '@ansvar-sakerhet/api-client';
import { Props } from '../ProjectsTable';
import { SortControls } from '../../../../../../../traits/api/projects/useGetMyProjects';

export const useMyProjectsTableData = (
  projects: FindAllMyProjectsResponse[]
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Namn',
        accessor: 'name',
        onClick: (setSort: Props['setSort'], currentSort: SortControls) => {
          if (currentSort === SortControls.ProjectNameAsc) {
            return setSort(SortControls.ProjectNameDesc);
          }

          return setSort(SortControls.ProjectNameAsc);
        },
        iconSrc: (currentSort: SortControls) =>
          currentSort === SortControls.ProjectNameAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Huvudentreprenör',
        accessor: 'mainContractor',
        onClick: (setSort: Props['setSort'], currentSort: SortControls) => {
          if (currentSort === SortControls.MainContractorAsc) {
            return setSort(SortControls.MainContractorDesc);
          }

          return setSort(SortControls.MainContractorAsc);
        },
        iconSrc: (currentSort: SortControls) =>
          currentSort === SortControls.MainContractorAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Platschef',
        accessor: 'siteManager',
        onClick: (setSort: Props['setSort'], currentSort: SortControls) => {
          if (currentSort === SortControls.SiteManagerAsc) {
            return setSort(SortControls.SiteManagerDesc);
          }

          return setSort(SortControls.SiteManagerAsc);
        },
        iconSrc: (currentSort: SortControls) =>
          currentSort === SortControls.SiteManagerAsc
            ? 'ArrowDropUp'
            : 'ArrowDropDown',
      },
      {
        Header: 'Antal ej åtgärdade avvikelser',
        accessor: 'deviationsRemaining',
        onClick: null,
        iconSrc: null,
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return projects.map((project) => {
      return {
        name: project.name,
        mainContractor: project.mainContractor,
        siteManager: project.siteManager,
        deviationsRemaining: `${project.deviationsRemaining ?? 0} st`,
        projectId: project.projectId,
      };
    });
  }, [projects]);

  return { columns, data };
};
