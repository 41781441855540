import React, { useState } from 'react';
import { S } from './OrderBackgroundControlButton.styles';
import { CompanyWithBackgroundControl } from '@ansvar-sakerhet/api-client';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { OrderControlDialog } from './components/OrderControlDialog';

type Props = {
  projectId: string;
  company: CompanyWithBackgroundControl;
};

export const OrderBackgroundControlButton = (props: Props) => {
  const [openAlert, setOpenAlert] = useState(false);
  const { BackgroundControlOrder } = props.company;
  const hasOrdered = BackgroundControlOrder.length > 0;

  if (hasOrdered) {
    const date = new Date(props.company.BackgroundControlOrder[0].createdAt);

    return (
      <a>
        <S.DownloadButton disabled>
          <S.IconWithBg src="Check" />
          <S.DownloadButtonText>
            Kontroll beställd {date.toLocaleDateString()}
          </S.DownloadButtonText>
        </S.DownloadButton>
      </a>
    );
  }
  return (
    <>
      <a onClick={() => setOpenAlert(true)}>
        <S.DownloadButton>
          <S.IconWithBg src="Plus" />{' '}
          <S.DownloadButtonText>
            Beställ en bakgrundskontroll
          </S.DownloadButtonText>
        </S.DownloadButton>
      </a>

      <AlertDialog.Root open={openAlert}>
        <OrderControlDialog
          onCancel={() => setOpenAlert(false)}
          projectId={props.projectId}
          company={props.company}
        />
      </AlertDialog.Root>
    </>
  );
};
