import { Theme } from './types';

export const defaultTheme: Theme = {
  colors: {
    primary: '#C8AB5D',
    secondary: '#023E52',
    secondaryLight: '#EBECF0',
    secondaryDark: '#1E2D3B',
    gray: '#CBCBCB',
    lighterGray: '#F2F4F6',
    lightGray: '#FBFBFC',
    darkGray: '#777777',
    white: '#FFFFFF',
    black: '#1B1B1B',
    success: '#0DA400',
    info: '#3A8DEB',
    error: '#F94144',
    warning: '#FFD600',
    lightRed: '#F7DCDC',
  },

  spacing: {
    none: '0',
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    '2xl': '40px',
    '3xl': '48px',
    '4xl': '56px',
    '5xl': '64px',
    '6xl': '72px',
    '7xl': '80px',
    auto: 'auto',
  },

  align: {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  },

  justify: {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
    even: 'space-evenly',
    around: 'space-around',
    between: 'space-between',
  },

  radius: {
    none: '0',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    '2xl': '40px',
    '3xl': '48px',
    '4xl': '56px',
    '5xl': '64px',
    '6xl': '72px',
    '7xl': '80px',
    full: '9999px',
  },
};
