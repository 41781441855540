import styled from 'styled-components';

export const THeadCell = styled.th`
  ${({ onClick }) => !!onClick && `cursor: pointer;`}
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};

  :not(:last-child) {
    margin-right: 10px;
  }
`;
