import React from 'react';
import { useAuth } from '../../../../traits/hooks/useAuth';

import { S } from './NotAllowed.styles';

export const NotAllowed: React.FC = () => {
  const { logout } = useAuth();

  return (
    <S.CenterSection>
      <S.Wrapper>
        <S.TextContainer>
          <S.B1 $align="center">
            Du har inte tillgång till denna sida tyvärr!
          </S.B1>
          <S.Button onClick={logout}>Logga ut</S.Button>
        </S.TextContainer>
      </S.Wrapper>
    </S.CenterSection>
  );
};
