import { useMemo } from 'react';
import { FindMyProjectWithControls } from '@ansvar-sakerhet/api-client';

export const useLatestControl = (
  project: FindMyProjectWithControls | undefined
) => {
  const latestControl = useMemo(() => {
    if (!project) {
      return null;
    }

    const sortedControls = project.controls.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return bDate.getTime() - aDate.getTime();
    });

    return sortedControls[0];
  }, [project]);

  return { latestControl };
};
