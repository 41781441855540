import styled from 'styled-components';

const MainContainer = styled.main`
  flex: 1;
  position: relative;
  display: flex;
  height: 100%;
`;

const DynamicContent = styled.div`
  width: 100%;
  padding: 2rem;
  overflow-y: scroll;
  background-color: #edeae8;
`;

export const S = {
  MainContainer,
  DynamicContent,
};
