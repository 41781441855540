import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const CenterSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${({ theme }) => theme.colors.secondary};

  &:before {
    height: 100vh;
    width: 100vw;
    content: '';
    background: linear-gradient(
      180deg,
      rgba(30, 45, 59, 0) 0%,
      rgba(0, 0, 0, 0.61) 100%
    );
  }
`;

const Wrapper = styled.div`
  position: absolute;
  height: 40vw;
  width: 50vw;
  max-width: 450px;
  max-height: 350px;
  min-height: 260px;
  min-width: 320px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.colors.white};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 40px;
`;

export const S = {
  ...UIElements,
  CenterSection,
  Wrapper,
  TextContainer,
};
