import styled from 'styled-components';
import { styledParagraph, Typography } from './BaseTypes';
import { styledMargin } from '../layout/Margin';

export const B4 = styled.p<Typography>`
  ${styledParagraph}
  ${styledMargin}
  font-size: 12px;
  line-height: 18px;
`;
