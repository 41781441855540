import React, { ReactNode } from 'react';
import { S } from './BackgroundControlsStats.style';
import { RenderCounterProps } from 'react-countup/build/types';
import CountUp from 'react-countup';
import { GetClientOverviewStatisticsDto } from '@ansvar-sakerhet/api-client';
import { PieChart } from '@mui/x-charts/PieChart';

type Props = {
  stats: GetClientOverviewStatisticsDto | undefined;
};

export const BackgroundControlsStats = ({ stats }: Props) => {
  if (!stats) return null;
  return (
    <S.StatsInner>
      <S.H6>Bakgrundskontroller</S.H6>
      <S.Margin $bottom="m" />

      <CountUpPreset value={stats.backgroundControls.amount}>
        {({ countUpRef }) => {
          return (
            <div style={{ display: 'flex' }}>
              <S.H3>
                <span ref={countUpRef} /> kontroller
              </S.H3>
            </div>
          );
        }}
      </CountUpPreset>
      <S.Margin $top="l" />
      <S.H6>Utfall från kontroller</S.H6>
      <PieChart
        series={[
          {
            data: [
              {
                id: 0,
                value: Math.round(
                  (stats.backgroundControls.noRisk /
                    stats.backgroundControls.amount) *
                    100
                ),
                label: `Inga risker identifierade (${Math.round(
                  (stats.backgroundControls.noRisk /
                    stats.backgroundControls.amount) *
                    100
                )}%)`,
                color: '#00b050',
              },
              {
                id: 1,
                value: Math.round(
                  (stats.backgroundControls.lesserRisk /
                    stats.backgroundControls.amount) *
                    100
                ),
                label: `Mindre väsentliga risker (${Math.round(
                  (stats.backgroundControls.lesserRisk /
                    stats.backgroundControls.amount) *
                    100
                )}%)`,
                color: '#ffff00',
              },
              {
                id: 2,
                value: Math.round(
                  (stats.backgroundControls.mediumHighRisk /
                    stats.backgroundControls.amount) *
                    100
                ),
                label: `Medel-hög risk (${Math.round(
                  (stats.backgroundControls.mediumHighRisk /
                    stats.backgroundControls.amount) *
                    100
                )}%)`,
                color: '#ff6405',
              },
              {
                id: 3,
                value: Math.round(
                  (stats.backgroundControls.highRisk /
                    stats.backgroundControls.amount) *
                    100
                ),
                label: `Hög risk (${Math.round(
                  (stats.backgroundControls.highRisk /
                    stats.backgroundControls.amount) *
                    100
                )}%)`,
                color: '#ff0000',
              },
            ],
            valueFormatter: (value) => `${value.value}%`,
          },
        ]}
        margin={{ top: 100, bottom: 0, left: 0, right: 0 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: {
              vertical: 'top',
              horizontal: 'left',
            },
            itemMarkWidth: 20,
            itemMarkHeight: 20,
            markGap: 5,
            itemGap: 10,
          },
        }}
      />
      <S.Margin $bottom="m" />
      <S.H6>Snitt i landet</S.H6>
      <PieChart
        series={[
          {
            data: [
              {
                id: 0,
                value: 33,
                label: `Inga risker identifierade (33%)`,
                color: '#00b050',
              },
              {
                id: 1,
                value: 25,
                label: `Mindre väsentliga risker (25%)`,
                color: '#ffff00',
              },
              {
                id: 2,
                value: 33,
                label: `Medel-hög risk (33%)`,
                color: '#ff6405',
              },
              {
                id: 3,
                value: 9,
                label: `Hög risk (9%)`,
                color: '#ff0000',
              },
            ],
            valueFormatter: (value) => `${value.value}%`,
          },
        ]}
        margin={{ top: 100, bottom: 0, left: 0, right: 0 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: {
              vertical: 'top',
              horizontal: 'left',
            },
            itemMarkWidth: 20,
            itemMarkHeight: 20,
            markGap: 5,
            itemGap: 10,
          },
        }}
      />

      {/* <S.RiskAssessmentContainer>
        <S.RiskAssesmentInner>
          <CountUpPreset value={stats.backgroundControls.noRisk}>
            {({ countUpRef }) => {
              return (
                <S.RiskSquare color="#DEFDAE">
                  <span>
                    Inga risker identifierade: <span ref={countUpRef} /> st
                  </span>
                </S.RiskSquare>
              );
            }}
          </CountUpPreset>
        </S.RiskAssesmentInner>
        <S.RiskAssesmentInner>
          <CountUpPreset value={stats.backgroundControls.lesserRisk}>
            {({ countUpRef }) => {
              return (
                <S.RiskSquare color="#FFFFB0">
                  <span>
                    Mindre väsentliga risker: <span ref={countUpRef} /> st
                  </span>
                </S.RiskSquare>
              );
            }}
          </CountUpPreset>
        </S.RiskAssesmentInner>

        <S.RiskAssesmentInner>
          <CountUpPreset value={stats.backgroundControls.mediumHighRisk}>
            {({ countUpRef }) => {
              return (
                <S.RiskSquare color="#D9BB77">
                  <span>
                    Medel-hög risk: <span ref={countUpRef} /> st
                  </span>
                </S.RiskSquare>
              );
            }}
          </CountUpPreset>
        </S.RiskAssesmentInner>
        <S.RiskAssesmentInner>
          <CountUpPreset value={stats.backgroundControls.highRisk}>
            {({ countUpRef }) => {
              return (
                <S.RiskSquare color="#E09E9B">
                  <span>
                    Hög risk: <span ref={countUpRef} /> st
                  </span>
                </S.RiskSquare>
              );
            }}
          </CountUpPreset>
        </S.RiskAssesmentInner>
      </S.RiskAssessmentContainer> */}
    </S.StatsInner>
  );
};

const CountUpPreset = ({
  children,
  value,
}: {
  children: (props: RenderCounterProps) => ReactNode;
  value: number;
}) => {
  return (
    <CountUp start={0} end={value} duration={3} delay={0}>
      {children}
    </CountUp>
  );
};
