import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';

export function useGetFrameCompany(frameCompanyId: string | undefined) {
  const getApi = useApi();

  const getFrameCompany = async () => {
    if (!frameCompanyId) return null;

    const api = await getApi();

    const res = await api.frameCompanyFindFrame(frameCompanyId);
    return res.data;
  };

  const query = useQuery({
    queryKey: keys.findOne(frameCompanyId),
    queryFn: getFrameCompany,
    keepPreviousData: true,
  });

  return {
    ...query,
  };
}
