import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
`;

export const S = { ...UIElements, Container };
