import styled from 'styled-components';

export const TBodyCell = styled.td`
  vertical-align: middle;
  padding: 16px;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};

  ${({ onClick }) => !!onClick && 'cursor: pointer;'}
`;
