import { User, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { toast } from '../../components/Toast';

export const useAuth = () => {
  const auth = useAuth0();

  useEffect(() => {
    if (!auth.error) return;

    if (auth.error.message === 'Popup closed') return;

    toast.error(
      'Något gick fel! Det gick inte att logga in. Försök igen senare eller kontakta supporten om problemet kvarstår.'
    );
  }, [auth.error]);

  useEffect(() => {
    if (!auth.user) return;
    localStorage.setItem('user', JSON.stringify(auth.user));
  }, [auth.user]);

  const user = useMemo((): User | undefined => {
    if (auth.user) {
      return auth.user;
    } else {
      const user = localStorage.getItem('user');
      if (user) return JSON.parse(user);
    }
  }, [auth.user]);

  const isCustomerAdmin = useMemo(() => {
    if (user && Array.isArray(user.user_roles)) {
      return user.user_roles.includes('customer-admin');
    } else return false;
  }, [user]);

  const accessToken = useMemo(() => {
    const token = localStorage.getItem('accessToken');
    if (token) return token;
  }, []);

  const login = async () => {
    try {
      await auth.loginWithPopup();
      const token = await auth.getAccessTokenSilently();
      localStorage.setItem('accessToken', token);
    } catch (error) {
      toast.error('Inloggning avbruten');
    }
  };

  const logout = async () => {
    await auth.logout();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
  };

  return {
    user,
    accessToken,
    isLoading: auth.isLoading,
    error: auth.error,
    isCustomerAdmin,
    login,
    logout,
  };
};
