import { useAuth } from './useAuth';

export const useUser = () => {
  const { user, isCustomerAdmin } = useAuth();

  if (!user) {
    throw new Error('useUser must be used only when user is authenticated');
  }

  return {
    user,
    isCustomerAdmin,
  };
};
