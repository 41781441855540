import React from 'react';
import { useMemo } from 'react';
import {
  BackgroundControlWithFiles,
  BackgroundControlWithFilesRiskEnum,
  FindAllFrameCompaniesResponse,
} from '@ansvar-sakerhet/api-client';
import { findLatestReport } from '../utils/findLatestReport';
import { OrderBackgroundControlButton } from '../../OrderBackgroundControlButton';
import { DownloadReportButton } from '../../DownloadReportButton';

export const useBackgroundControlsTableData = (
  backgroundControls: BackgroundControlWithFiles[],
  frameCompany: FindAllFrameCompaniesResponse
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Bortvalt',
        accessor: 'optedOut',
      },
      {
        Header: 'Riskbedömning',
        accessor: 'risks',
      },
      {
        Header: 'Rapport',
        accessor: 'report',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return backgroundControls.map((backgroundControl) => {
      return {
        optedOut: optedOutTranslation(backgroundControl?.optedOut) ?? '-',
        risks: AcceptanceTranslation[backgroundControl?.risk] ?? '-',
        report: getDynamicReportButton(backgroundControl, frameCompany),
      };
    });
  }, [backgroundControls]);

  return { columns, data };
};

const optedOutTranslation = (optedOut: boolean) => (optedOut ? 'Ja' : 'Nej');

const AcceptanceTranslation: Record<
  BackgroundControlWithFilesRiskEnum,
  string
> = {
  NoRisk: 'Inga risker identifierade.',
  LesserRisk: 'Avvikelser identifierade, OK att samarbeta.',
  MediumHighRisk: 'Avvikelser identifierade, åtgärder föreslås.',
  HighRisk:
    'Väsentliga risker. Avrådan från att anlita företaget eller åtgärda avvikelser.',
};

function getDynamicReportButton(
  backgroundControl: BackgroundControlWithFiles | undefined,
  contractor: FindAllFrameCompaniesResponse
) {
  switch (backgroundControl?.risk) {
    case BackgroundControlWithFilesRiskEnum.NoRisk:
    case BackgroundControlWithFilesRiskEnum.LesserRisk:
    case BackgroundControlWithFilesRiskEnum.MediumHighRisk:
    case BackgroundControlWithFilesRiskEnum.HighRisk:
      const downloadUrl = findLatestReport(backgroundControl)?.file.url;

      return (
        <DownloadReportButton
          risk={backgroundControl.risk}
          downloadUrl={downloadUrl}
        />
      );
    default:
      return <OrderBackgroundControlButton company={contractor} />;
  }
}
