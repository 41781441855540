import { useMemo } from 'react';
import { FindMyProjectWithControls } from '@ansvar-sakerhet/api-client';

export const useContractors = (
  project: FindMyProjectWithControls | undefined
) => {
  const mainContractors = useMemo(() => {
    const controls = project?.controls ?? [];

    return controls.flatMap(({ mainContractorCompanyId, companies }) => {
      return (
        companies.find(
          ({ companyId }) => companyId === mainContractorCompanyId
        ) ?? []
      );
    });
  }, [project]);

  const subcontractors = useMemo(() => {
    const controls = project?.controls ?? [];

    const nonUniqueSubcontractors = controls.flatMap(({ companies }) => {
      return companies.filter(({ registrationNumber: regNrToFind }) => {
        const companyAsMainContractor = mainContractors.find(
          ({ registrationNumber }) => registrationNumber === regNrToFind
        );

        return !companyAsMainContractor;
      });
    });

    return nonUniqueSubcontractors.filter(
      ({ registrationNumber: regNrToFind }, index, arr) => {
        const foundIndex = arr.findIndex(
          ({ registrationNumber }) => registrationNumber === regNrToFind
        );

        return foundIndex === index;
      }
    );
  }, [project]);

  return { subcontractors, mainContractors };
};
