import React from 'react';
import { FindMyProjectWithControls } from '@ansvar-sakerhet/api-client';
import { S } from './ControlsTable.styles';
import { useTable } from 'react-table';
import { useControlsTableData } from './hooks/useControlsTableData';

export type Props = {
  controls: FindMyProjectWithControls['controls'];
};

export const ControlsTable = ({ controls }: Props) => {
  const data = useControlsTableData(controls);
  const table = useTable(data);

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                const headerData = data.columns.filter(
                  ({ accessor }) => accessor === column.id
                )[0];
                const interactiveProps = headerData?.onClick
                  ? { onClick: headerData?.onClick }
                  : {};
                const iconSrc = headerData?.iconSrc?.();

                return (
                  <S.AltTHeadCell
                    {...interactiveProps}
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                    {iconSrc && <S.CenteredIcon src={iconSrc} />}
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <S.AltTBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer>
  );
};
