import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { toast } from '../../../components/Toast';

export function useCreateTag() {
    const getApi = useApi();
    const queryClient = useQueryClient();

    const mutationFn = async (tagName: string) => {
        const api = await getApi();
        const res = await api.frameCompanyCreateTag({ name: tagName });
        return res.data;
    };

    const onSuccess = () => {
        toast.success('Taggen har lagts till');
        queryClient.invalidateQueries({ queryKey: ['tags'] });
    };

    const onError = () => {
        toast.error('Något gick fel');
    };

    const mutation = useMutation({
        mutationFn,
        onSuccess,
        onError,
    });

    return mutation;
}
