import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  flex-grow: 1;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const DeleteDataCell = styled.td`
  vertical-align: middle;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  min-width: 210px;
`;

const CenteredIcon = styled(UIElements.Icon)`
  vertical-align: middle;
`;

const TableText = styled.p`
  display: inline;
`;

export const S = {
  ...UIElements,
  TableText,
  CenteredIcon,
  TableContainer,
  PaginationContainer,
  DeleteDataCell,
};
