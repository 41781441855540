import React from 'react';
import { Spinner } from '../Spinner';
import { S } from './Loading.styles';

export const Loading: React.FC = () => {
  return (
    <S.LoadingContainer>
      <Spinner color="darkGray" />
    </S.LoadingContainer>
  );
};
