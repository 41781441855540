import React, { useEffect, useState } from 'react';
import { MultiSelect, Option } from '../../../../../components/MultiSelect';
import { S } from './ProjectFlow.styles';
import { useGetStats } from '../../../../../traits/api/stats/useGetStats';
import { FindAllControlStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { ProjectStatisticsTable } from './components/ProjectStatisticsTable';
import { useGetMyProjectsWithStatistics } from '../../../../../traits/api/projects/useGetMyProjectsWithStatistics';

export const ProjectFlow = () => {
  const [chosenProjectOptions, setChosenProjectOptions] = useState<Option[]>(
    []
  );

  const [publicStats, setPublicStats] = useState<
    { data: FindAllControlStatisticsResponse; clientId: string }[]
  >([]);

  const stats = useGetStats();

  const projects = useGetMyProjectsWithStatistics(0, undefined, 1000);

  const projectOptions = projects.data
    ? projects.data.map((project) => {
        return {
          key: project.projectId,
          label: project.name,
        } as Option;
      })
    : [];

  // TODO: Create a hook for this to make state handling easier (isLoading etc)

  useEffect(() => {
    const getProject = async (clientId: string) => {
      const a = await stats.mutateAsync(clientId);
      return { data: a.data, clientId };
    };

    const maps = chosenProjectOptions.map((option) => {
      return getProject(option.key);
    });

    Promise.all(maps).then((values) => {
      setPublicStats(values);
    });
  }, [chosenProjectOptions]);

  return (
    <>
      <MultiSelect
        searchPlaceholder="Sök projekt"
        chosenOptions={chosenProjectOptions}
        setChosenOptions={setChosenProjectOptions}
        options={projectOptions}
        noResultsText="Inga projekt med statistik finns att visa"
        label="Välj vilka projekt du vill jämföra"
      />
      <S.Margin $top="m" />

      {publicStats.length > 0 && (
        <ProjectStatisticsTable
          statistics={publicStats.map((stat) => {
            return {
              data: stat.data,
              clientId: stat.clientId,
              projectName:
                projects.data?.find(
                  (project) => project.projectId === stat.clientId
                )?.name || '',
            };
          })}
        />
      )}
    </>
  );
};
