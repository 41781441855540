import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const TContainer = styled.div`
  max-width: 750px;
`;

const TWrapper = styled(UIElements.TWrapper)`
  border: none;
`;

const TBodyRow = styled(UIElements.TBodyRow)`
  &:nth-child(even) {
    background: white;
  }

  border-right: 1px solid #c1a07f;
  border-left: 1px solid #c1a07f;

  &:last-child {
    border-bottom: 1px solid #c1a07f;
  }
`;

const TBodyCell = styled(UIElements.TBodyCell)`
  border-right: 1px solid #c1a07f;
`;

const THeadRow = styled(UIElements.THeadRow)`
  background: white;
  border: 1px solid #c1a07f;
`;

const THeadCell = styled(UIElements.THeadCell)`
  & > p {
    color: 'black';
  }

  width: 50%;

  border-right: 1px solid #c1a07f;

  &:last-child {
    border-right: none;
  }
`;

export const S = {
  ...UIElements,
  TContainer,
  TWrapper,
  TBodyRow,
  TBodyCell,
  THeadRow,
  THeadCell,
};
