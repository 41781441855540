import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';

export function useGetTags() {
    const getApi = useApi();
    const getTags = async () => {
        const api = await getApi();
        const res = await api.frameCompanyFindTags();
        return res;
    };

    const query = useQuery({
        queryFn: getTags,
        queryKey: ['tags'],
    });

    return {
        ...query,
        data: query.data?.data,
    };
}
