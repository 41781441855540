import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { toast } from '../../../components/Toast';
import { CreateBackgroundControlOrderFrameDto } from '@ansvar-sakerhet/api-client';

export function createFrameCompanyBackgroundOrder() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const createFrameCompany = async (
    data: CreateBackgroundControlOrderFrameDto
  ) => {
    const api = await getApi();

    const res = await api.backgroundControlsOrderOrderFrame({
      ...data,
    });

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Order har lagts');
    queryClient.invalidateQueries({ queryKey: keys.all });
  };

  const onError = () => {
    toast.error('Något gick fel');
  };

  const mutation = useMutation(createFrameCompany, {
    onSuccess,
    onError,
  });

  return mutation;
}
