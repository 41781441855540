import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { S } from './OrderControlDialog.styles';
import { CompanyWithBackgroundControl } from '@ansvar-sakerhet/api-client';
import { useOrderBackgroundControl } from '../../../../../../../../../../traits/api/projects/useOrderBackgroundControl';

type Props = {
  projectId: string;
  company: CompanyWithBackgroundControl;
  onCancel: () => void;
};

export const OrderControlDialog: React.FC<Props> = (props) => {
  const order = useOrderBackgroundControl(
    props.company.companyId,
    props.projectId
  );
  const { BackgroundControlOrder } = props.company;
  const hasOrdered = BackgroundControlOrder.length > 0;

  const orderBg = async () => {
    if (hasOrdered) return;
    await order.mutateAsync();
    props.onCancel();
  };

  return (
    <AlertDialog.Portal>
      <S.Overlay>
        <S.Wrapper>
          <S.CancelButton onClick={props.onCancel}>
            <S.Icon src="Close" width={24} height={24} />
          </S.CancelButton>

          <S.ContentContainer>
            <img src={Images.Confirmation} width={295} height={215} />

            <AlertDialog.Title asChild>
              <S.H5>
                Vill du beställa en bakgrundskontroll på {props.company.name}{' '}
                orgnr. {props.company.registrationNumber}?
              </S.H5>
            </AlertDialog.Title>

            <S.ButtonContainer>
              <AlertDialog.Cancel asChild>
                <S.Button $outlined onClick={props.onCancel}>
                  Avbryt
                </S.Button>
              </AlertDialog.Cancel>

              <AlertDialog.Action asChild>
                <S.Button onClick={orderBg} disabled={order.isLoading}>
                  Bekräfta
                </S.Button>
              </AlertDialog.Action>
            </S.ButtonContainer>
          </S.ContentContainer>
        </S.Wrapper>
      </S.Overlay>
    </AlertDialog.Portal>
  );
};
