import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import { Flex } from '@ansvar-sakerhet/ui/src/layout/Flex';

const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin: 0px 6px 0px 0px;
  transition: transform 300ms;
`;

const Trigger = styled.button<{ $isExpanded: boolean }>`
  all: unset;
  box-sizing: border-box;
  padding: 0.6rem 1rem 0.6rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  width: 100%;
  border-radius: 4px 4px
    ${({ $isExpanded }) => ($isExpanded ? '0px 0px' : '4px 4px')};
  border-bottom: ${({ $isExpanded, theme }) =>
    $isExpanded ? 'none' : `solid 1px ${theme.colors.gray}`};

  &:focus {
    outline: none;
  }

  ${AnimatedIcon} {
    transform: rotate(
      ${({ $isExpanded }) => ($isExpanded ? '180deg' : '0deg')}
    );
  }
`;

const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-top: none;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
`;

const ContentText = styled.p`
  line-height: 20px;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: 0;
`;

const EmailLink = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;

const Image = styled.img`
  height: auto;
  width: 50%;
`;

const Title = styled(H3)`
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

const FaqSubContainer = styled(Flex)`
  width: 70%;
`;

export const S = {
  ...UIElements,
  Container,
  Trigger,
  AnimatedIcon,
  Content,
  ContentText,
  EmailLink,
  Image,
  Title,
  FaqSubContainer,
};
