import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React, { useState } from 'react';
import { S } from './FrameCompanies.styles';
import { useGetAllFrameCompanies } from '../../../../traits/api/frameCompany/useGetAllFrameCompanies';
import { Pagination } from '../../../../components/Pagination';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';
import { FrameCompaniesTable } from './components/FrameCompaniesTable';
import { SearchInput } from '../../../../components/SearchInput';
import { MultiSelect } from '../../../../components/MultiSelect';
import { useGetTags } from '../../../../traits/api/frameCompany/useGetTags';
import { H5 } from '@ansvar-sakerhet/ui/src/typography/H5';
import { Select } from '../../../../components/Select';
import { useFindAllUsersFrameCompany } from '../../../../traits/api/frameCompany/useFindAllUsersFrameCompany';

export const FrameCompanies: React.FC = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedTags, setSelectedTags] = useState([] as string[]);
  const [responsible, setResponsible] = useState<string | null>(
    'no_responsible'
  );
  const { data: frameCompanyUsers } = useFindAllUsersFrameCompany();
  const navigate = useNavigate();
  const PAGE_SIZE = 30;

  const { data, isLoading, count } = useGetAllFrameCompanies(
    PAGE_SIZE,
    page * PAGE_SIZE,
    search,
    selectedTags,
    responsible
  );
  const { data: tagList, isLoading: tagLoading } = useGetTags();
  if (isLoading || !data) {
    return (
      <div>
        <S.Flex $flexDir="row" $justify="between">
          <H3>Avtalsföretag</H3>
          <S.Button onClick={() => navigate('/ramavtals-foretag/skapa')}>
            <S.Icon src="Plus" /> Nytt Företag
          </S.Button>
        </S.Flex>
        <S.Margin $top="m" />
        <SearchInput
          placeholder="Sök efter organisationsnummer eller namn"
          input={search}
          setInput={setSearch}
        />
        <S.Margin $top="m" />
        <Loading />
      </div>
    );
  }

  if (tagLoading || !tagList) {
    return <Loading />;
  }
  const lista = tagList as any;
  const tags = lista.map((tag: any) => ({
    tagId: tag.tagId,
    tagName: tag.name,
  }));
  return (
    <div>
      <S.Flex $flexDir="row" $justify="between">
        <H3>Avtalsföretag</H3>
        <S.Button onClick={() => navigate('/ramavtals-foretag/skapa')}>
          <S.Icon src="Plus" /> Nytt Företag
        </S.Button>
      </S.Flex>
      <S.Margin $top="m" />
      <SearchInput
        placeholder="Sök efter organisationsnummer eller namn"
        input={search}
        setInput={setSearch}
      />
      <S.Margin $top="s" />
      <S.Flex $flexWrap="wrap" $gap={12} $justify={'space-between'}>
        <div>
          <H5>Filtrera efter taggar</H5>
          <MultiSelect
            label=""
            width={500}
            options={tags.map((tag: any) => ({
              key: tag.tagId,
              label: tag.tagName,
            }))}
            chosenOptions={selectedTags.map((tag) => ({
              key: tag,
              label: tags.find((t: any) => t.tagId === tag)?.tagName ?? '',
            }))}
            setChosenOptions={(tags) =>
              setSelectedTags(tags.map((tag) => tag.key))
            }
          />
        </div>
        <div style={{ height: '100%' }}>
          <H5>Filtrera efter ansvarig person</H5>
          <Select
            style={{ height: '100%' }}
            label=""
            value={responsible === null ? undefined : responsible}
            onChange={(e) => setResponsible(e.target.value)}
          >
            {frameCompanyUsers?.customerUsers.map((user: any) => (
              <option key={user.customerUserId} value={user.customerUserId}>
                {user.firstName} {user.lastName}
              </option>
            ))}
            <option key={'no_responsible'} value={'no_responsible'}>
              -
            </option>
          </Select>
        </div>
      </S.Flex>

      <S.Margin $top="m" />
      <S.FrameCompanies>
        <FrameCompaniesTable frameCompanies={data} />
      </S.FrameCompanies>
      <S.Margin $top="m" />
      <Pagination
        numberOfPages={Math.ceil(count / PAGE_SIZE)}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};
