import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const TWrapper = styled(UIElements.TWrapper)`
  border: none;
`;

const Icon = styled(UIElements.Icon)`
  color: black;
  height: 17px;
  margin-right: 5px;
`;

const TBodyRow = styled(UIElements.TBodyRow)`
  &:nth-child(even) {
    background: white;
  }

  &:hover {
    background: #c8ab5d;
  }

  border-right: 1px solid black;
  border-left: 1px solid black;

  &:last-child {
    border-bottom: 1px solid black;
  }
`;

const TBodyCell = styled(UIElements.TBodyCell)`
  border-right: none;
  font-size: 13px;
  &:first-child {
    color: 'black';
    font-weight: bold;
  }

  &:last-child {
    background: rgba(200, 171, 93, 0.1);
  }
`;

const THeadRow = styled(UIElements.THeadRow)`
  background: white;
  border: 1px solid black;
`;

const THeadCell = styled(UIElements.THeadCell)`
  & > p {
    color: 'black';
    font-weight: bold;
  }

  border-right: none;

  &:last-child {
    border-right: none;
    background: rgba(200, 171, 93, 0.1);
  }
`;

export const S = {
  ...UIElements,
  TWrapper,
  TBodyRow,
  TBodyCell,
  THeadRow,
  THeadCell,
  Icon,
};
