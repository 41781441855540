import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React, { useEffect } from 'react';
import { S } from './FrameCompany.styles';
import { BackgroundControlsTable } from './components/BackgroundControlsTable';
import { Checkbox } from '../../../../components/Checkbox';
import { Select } from '../../../../components/Select';
import { H5 } from '@ansvar-sakerhet/ui/src/typography/H5';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../../../components/Input';
import { useGetFrameCompany } from '../../../../traits/api/frameCompany/useGetFrameCompany';
import { Textarea } from '@ansvar-sakerhet/ui/src/inputs/Textarea';
import { useUpdateFrameCompany } from '../../../../traits/api/frameCompany/useUpdateFrameCompany';
import { useDeleteFrameCompany } from '../../../../traits/api/frameCompany/useDeleteFrameCompany';
import { useFindAllUsersFrameCompany } from '../../../../traits/api/frameCompany/useFindAllUsersFrameCompany';
import { useGetTags } from '../../../../traits/api/frameCompany/useGetTags';
import { MultiSelect } from '../../../../components/MultiSelect';
import { useCreateTag } from '../../../../traits/api/frameCompany/useCreateTag';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Images } from '@ansvar-sakerhet/ui/src/assets/images';
import { useGetClient } from '../../../../traits/api/client/useClient';
import { useGetFiles } from '../../../../traits/api/file/useFiles';
import { FieldFile } from './components/FieldFile/FieldFile';
import { CreditControlPage } from './components/CreditControl/CreditControl';

export const FrameCompany: React.FC = () => {
  const navigate = useNavigate();

  const { frameCompanyId } = useParams();
  const { data: frameCompanyUsers } =
    useFindAllUsersFrameCompany();
  const { data, isLoading } = useGetFrameCompany(frameCompanyId);
  const { data: tagList } = useGetTags();
  const useFile = useGetFiles();
  const [tags, setTags] = React.useState<{ tagId: string; tagName: string }[]>(
    []
  );

  const createTag = useCreateTag();

  const { data: clientData } = useGetClient();

  const [openAlert, setOpenAlert] = React.useState(false);
  const [registrationNumber, setRegistrationNumber] = React.useState(
    data?.registrationNumber
  );
  const [creditControlDone, setCreditControlDone] = React.useState(
    data?.creditControlDone
  );
  const [creditControlComment, setCreditControlComment] = React.useState(
    data?.creditControlComment
  );
  const [insuranceControlDone, setInsuranceControlDone] = React.useState(
    data?.insuranceControlDone
  );
  const [insuranceControlComment, setInsuranceControlComment] = React.useState(
    data?.insuranceControlComment
  );
  const [behaviourControlDone, setBehaviourControlDone] = React.useState(
    data?.behaviourControlDone
  );
  const [behaviourControlComment, setBehaviourControlComment] = React.useState(
    data?.behaviourControlComment
  );

  const [insuranceNextDate, setInsuranceNextDate] = React.useState(
    data?.insuranceControlNext ?? '');

  const [environmentalControlDone, setEnvironmentalControlDone] =
    React.useState(data?.environmentalControlDone);
  const [environmentalControlComment, setEnvironmentalControlComment] =
    React.useState(data?.environmentalControlComment);
  const [size, setSize] = React.useState<string | undefined>();
  const [building, setBuilding] = React.useState<string | undefined>();
  const [responsible, setResponsible] = React.useState<
    string | null | undefined
  >(data?.customerUserCustomerUserId);

  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [newTagValue, setNewTagValue] = React.useState('');

  const { mutate } = useUpdateFrameCompany(frameCompanyId);
  const frameCompany = useDeleteFrameCompany();

  useEffect(() => {
    if (data) {
      setRegistrationNumber(data.registrationNumber);
      setCreditControlDone(data.creditControlDone);
      setCreditControlComment(data.creditControlComment);
      setInsuranceControlDone(data.insuranceControlDone);
      setInsuranceControlComment(data.insuranceControlComment);
      setInsuranceNextDate(data.insuranceControlNext ? new Date(data.insuranceControlNext).toISOString().split('T')[0] : '');
      setBehaviourControlDone(data.behaviourControlDone);
      setBehaviourControlComment(data.behaviourControlComment);
      setEnvironmentalControlDone(data.environmentalControlDone);
      setEnvironmentalControlComment(data.environmentalControlComment);
      setSize(data.size as unknown as string);
      setBuilding(data.building);
      setSelectedTags(
        data.FrameCompanyTagRelation.map((tag: any) => tag.tagId)
      );
      setResponsible(
        data.customerUserCustomerUserId === null
          ? 'no_responsible'
          : data.customerUserCustomerUserId
      );
    }
  }, [data]);

  useEffect(() => {
    if (tagList) {
      const lista = tagList as any;
      setTags(
        lista.map((tag: any) => ({ tagId: tag.tagId, tagName: tag.name }))
      );
    }


  }, [tagList]);


  const openLink = async () => {
    window.open(await useFile(clientData?.policyLink ?? ''))
  }

  const deleteCompany = () => {
    frameCompany.mutate(frameCompanyId);
    navigate('/ramavtals-foretag');
  };

  const update = () => {
    const responsibleS = responsible === 'no_responsible' ? null : responsible;
    mutate({
      registrationNumber: registrationNumber ?? '',
      creditControlDone: !!creditControlDone,
      creditControlComment: creditControlComment ?? '',
      insuranceControlDone: !!insuranceControlDone,
      insuranceControlComment: insuranceControlComment ?? '',
      behaviourControlDone: !!behaviourControlDone,
      insuranceControlNext: insuranceNextDate && insuranceNextDate !== '' ? insuranceNextDate : null,
      behaviourControlComment: behaviourControlComment ?? '',
      environmentalControlDone: !!environmentalControlDone,
      environmentalControlComment: environmentalControlComment ?? '',
      size: size as string,
      building: building ?? '',
      customerUserCustomerUserId: responsibleS ?? null,
      tags: selectedTags,
      creditControlUrl: undefined,
      insuranceControlUrl: undefined,
      behaviourControlUrl: undefined,
      environmentalControlUrl: undefined,
    });
  };
  if (!data || isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div>
      <AlertDialog.Root open={openAlert}>
        <AlertDialog.Portal>
          <S.Overlay>
            <S.Wrapper>
              <S.CancelButton onClick={() => setOpenAlert(false)}>
                <S.Icon src="Close" width={24} height={24} />
              </S.CancelButton>

              <S.ContentContainer>
                <img src={Images.Confirmation} width={295} height={215} />
                <AlertDialog.Title asChild>
                  <S.H5>
                    Vill du verkligen ta bort detta bolag?
                  </S.H5>
                </AlertDialog.Title>

                <S.ButtonContainer>
                  <AlertDialog.Cancel asChild>
                    <S.Button $outlined onClick={() => { setOpenAlert(false) }}>
                      Avbryt
                    </S.Button>
                  </AlertDialog.Cancel>

                  <AlertDialog.Action asChild>
                    <S.Button onClick={deleteCompany} >
                      Bekräfta
                    </S.Button>
                  </AlertDialog.Action>
                </S.ButtonContainer>
              </S.ContentContainer>
            </S.Wrapper>
          </S.Overlay>
        </AlertDialog.Portal>
      </AlertDialog.Root>
      <S.Flex style={{ justifyContent: 'space-between' }}>
        <S.BackButton onClick={() => navigate(-1)}>
          <S.Icon src="ChevronLeft" />
          <p>Tillbaka</p>
        </S.BackButton>
        {
          clientData?.policyLink ? (
            <S.Button onClick={() => {
              openLink();
            }}>
              Ladda ner rutin
            </S.Button>
          ) : null
        }

      </S.Flex>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: '30%'}}>
          <S.H3>{data.name}</S.H3>
          <S.Margin $top="s" />
          <Input
            style={{ maxWidth: '400px' }}
            value={registrationNumber}
            onChange={(e) => setRegistrationNumber(e.target.value)}
            placeholder="Organisationsnummer"
          />
          <S.Margin $top="xl" />

          <S.Form>
            <S.Margin $top="s" />
            <S.Margin $top="m" $bottom="m">
              <H5>1. Kreditkontroll</H5>
              <S.Margin $top="s" />
              <Checkbox
                checked={creditControlDone}
                onChange={() => setCreditControlDone(!creditControlDone)}
                label="Kreditkontroll tagen"
              />
              <i style={{ fontSize: 14 }}>
                {data.creditControlDoneAt
                  ? new Date(data.creditControlDoneAt).toLocaleDateString()
                  : null}
              </i>
              <S.Margin $top="s" />
              <Input
                value={creditControlComment}
                onChange={(e) => setCreditControlComment(e.target.value)}
                placeholder="Kommentar kreditkontroll"
              />
              <S.Margin $top="s" />
              <FieldFile type='credit' frameCompanyId={frameCompanyId} file={data.creditControlUrl ? { path: data.creditControlUrl } : undefined} />
              <S.HR />
            </S.Margin>

            <S.Margin $top="m" $bottom="m">
              <H5>2. Ansvarsförsäkring</H5>
              <S.Margin $top="s" />
              <Checkbox
                checked={insuranceControlDone}
                onChange={() => setInsuranceControlDone(!insuranceControlDone)}
                label="Kontroll ansvarsförsäkring"
              />
              <i style={{ fontSize: 14 }}>
                {data.insuranceControlDoneAt
                  ? new Date(data.insuranceControlDoneAt).toLocaleDateString()
                  : null}
              </i>
              <S.Margin $top="s" />
              <Input
                value={insuranceControlComment}
                onChange={(e) => setInsuranceControlComment(e.target.value)}
                placeholder="Kommentar ansvarsförsäkring"
              />
              <S.Margin $top="s" />
              <S.Label>Datum för nästa kontroll</S.Label>
              <Input type='date' value={insuranceNextDate} onChange={(e) => {
                setInsuranceNextDate(e.target.value)
              }} />
              <S.Margin $top="s" />
              <FieldFile type='insurance' frameCompanyId={frameCompanyId} file={data.insuranceControlUrl ? { path: data.insuranceControlUrl } : undefined} />
              <S.HR />
            </S.Margin>

            <S.Margin $top="m" $bottom="m">
              <H5>3. Underskrift uppförandekod</H5>
              <S.Margin $top="s" />
              <Checkbox
                checked={behaviourControlDone}
                onChange={() => setBehaviourControlDone(!behaviourControlDone)}
                label="Underskrift uppförandekod"
              />
              <i style={{ fontSize: 14 }}>
                {data.behaviourControlDoneAt
                  ? new Date(data.behaviourControlDoneAt).toLocaleDateString()
                  : null}
              </i>
              <S.Margin $top="s" />
              <Input
                value={behaviourControlComment}
                onChange={(e) => setBehaviourControlComment(e.target.value)}
                placeholder="Kommentar uppförandekod"
              />
              <S.Margin $top="s" />
              <FieldFile type='behaviour' frameCompanyId={frameCompanyId} file={data.behaviourControlUrl ? { path: data.behaviourControlUrl } : undefined} />
              <S.HR />
            </S.Margin>

            <S.Margin $top="m" $bottom="m">
              <H5>4. Miljö och kvalitétsarbete</H5>
              <S.Margin $top="s" />
              <Checkbox
                checked={environmentalControlDone}
                onChange={() =>
                  setEnvironmentalControlDone(!environmentalControlDone)
                }
                label="Kontroll miljö och kvalitétsarbete"
              />
              <i style={{ fontSize: 14 }}>
                {data.environmentalControlDoneAt
                  ? new Date(data.environmentalControlDoneAt).toLocaleDateString()
                  : null}
              </i>
              <S.Margin $top="s" />
              <Input
                value={environmentalControlComment}
                onChange={(e) => setEnvironmentalControlComment(e.target.value)}
                placeholder="Kommentar miljö och kvalitétsarbete"
              />
              <S.Margin $top="s" />
              <FieldFile type='environmental' frameCompanyId={frameCompanyId} file={data.environmentalControlUrl ? { path: data.environmentalControlUrl } : undefined} />
              <S.HR />
            </S.Margin>
            <S.Margin $top="m" $bottom="m">
              <H5>Storlek på projekt</H5>
              <S.Margin $top="s" />

              <Select
                label=""
                value={size}
                onChange={(e) => setSize(e.target.value)}
              >
                <option value={'Small'}>Litet</option>
                <option value={'Medium'}>Mellan</option>
                <option value={'Large'}>Stora</option>
              </Select>
              <S.HR />
            </S.Margin>

            <S.Margin $top="m" $bottom="m">
              <H5>5. Leverantörsansvarig</H5>
              <S.Margin $top="s" />
              <Select
                label="Ansvarig"
                value={responsible === null ? undefined : responsible}
                onChange={(e) => setResponsible(e.target.value)}
              >
                {frameCompanyUsers?.customerUsers.map((user: any) => (
                  <option key={user.customerUserId} value={user.customerUserId}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
                <option key={'no_responsible'} value={'no_responsible'}>
                  Ingen ansvarig
                </option>
              </Select>
              <S.HR />
            </S.Margin>
            <S.Margin $top="m" $bottom="m">
              <H5>6. Taggar</H5>
              <S.Margin $top="s" />
              <MultiSelect
                label=""
                options={tags.map((tag) => ({
                  key: tag.tagId,
                  label: tag.tagName,
                }))}
                chosenOptions={selectedTags.map((tag) => ({
                  key: tag,
                  label: tags.find((t) => t.tagId === tag)?.tagName ?? '',
                }))}
                setChosenOptions={(tags) =>
                  setSelectedTags(tags.map((tag) => tag.key))
                }
              ></MultiSelect>
              <S.Margin $top="m" />
              <Input
                placeholder="Ny tagg"
                value={newTagValue}
                onChange={(e) => setNewTagValue(e.target.value)}
              />
              <S.Margin $top="s" />
              <S.Button
                onClick={(e) => {
                  e.preventDefault();
                  createTag.mutate(newTagValue);
                  setNewTagValue('');
                }}
              >
                Skapa ny tagg
              </S.Button>
              <S.Margin $top="m" />
              <S.HR />
            </S.Margin>
            <S.Margin $top="m" $bottom="m">
              <H5>Kommentarer</H5>
              <S.Margin $top="s" />

              <Textarea
                value={building}
                onChange={(e) => setBuilding(e.target.value)}
                placeholder=""
              />
              <S.HR />
            </S.Margin>
          </S.Form>

          <S.Button onClick={update}>Uppdatera</S.Button>
        </div>
        <div style={{width: '60%'}}>
          <S.H4>Kreditkontroll</S.H4>
          <CreditControlPage frameCompanyId={data.frameCompanyId} creditControl={data.CreditControl[data.CreditControl.length - 1]} />
        </div>
      </div>
      <H5 style={{marginTop: 30}}>Bakgrundskontroller</H5>
      <S.Margin $top="s" />
      <BackgroundControlsTable
        frameCompany={data}
        backgroundControls={data.BackgroundControl}
      />
      <S.Margin $top="m" />
      <S.Button style={{ backgroundColor: ' #C70000' }} onClick={() => setOpenAlert(true)}>
        Radera bolag
      </S.Button>
    </div >

  );
};
