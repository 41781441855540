import { FindAllFrameCompaniesResponse } from '@ansvar-sakerhet/api-client';
import { SortControls } from '../hooks/useFrameCompaniesTableData';

export function sortFrameCompanies<T extends FindAllFrameCompaniesResponse>(
  contractors: T[],
  sort: SortControls
) {
  switch (sort) {
    case SortControls.NameAsc:
      return contractors.sort((a, b) => a.name.localeCompare(b.name));
    case SortControls.NameDesc:
      return contractors.sort((a, b) => b.name.localeCompare(a.name));
    case SortControls.RegistrationNumberAsc:
      return contractors.sort((a, b) =>
        a.registrationNumber.localeCompare(b.registrationNumber)
      );
    case SortControls.RegistrationNumberDesc:
      return contractors.sort((a, b) =>
        b.registrationNumber.localeCompare(a.registrationNumber)
      );
    case SortControls.LatestBackgroundControlAsc:
      return contractors.sort((a, b) => {
        const aLatestControl = getLatestBackgroundControl(a);
        const bLatestControl = getLatestBackgroundControl(b);

        if (!aLatestControl) return 0;
        if (!bLatestControl) return Infinity;

        return (
          new Date(aLatestControl.createdAt).getTime() -
          new Date(bLatestControl.createdAt).getTime()
        );
      });
    case SortControls.LatestBackgroundControlDesc:
      return contractors.sort((a, b) => {
        const aLatestControl = getLatestBackgroundControl(a);
        const bLatestControl = getLatestBackgroundControl(b);

        if (!aLatestControl) return Infinity;
        if (!bLatestControl) return 0;

        return (
          new Date(bLatestControl.createdAt).getTime() -
          new Date(aLatestControl.createdAt).getTime()
        );
      });
    default:
      return contractors;
  }
}

function getLatestBackgroundControl(company: FindAllFrameCompaniesResponse) {
  return company.BackgroundControl.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0];
}
