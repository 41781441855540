import { FindMyProjectWithControls } from '@ansvar-sakerhet/api-client';
import { DEVIATION_FIELDS } from '../../../../../utils/DEVIATION_FIELDS';

export function getAmountOfDeviations(
  control: FindMyProjectWithControls['controls'][number]
) {
  const fieldsToHandle = Object.entries(DEVIATION_FIELDS).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, { shouldHandle }]) => shouldHandle
  );

  const amountOfDeviations = fieldsToHandle.reduce((previousValue, [key]) => {
    const action = control[key as keyof typeof control];

    if (action) return previousValue + 1;

    return previousValue;
  }, 0);

  return amountOfDeviations;
}
