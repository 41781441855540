import React from 'react';
import { S } from './CreditControl.styles';
import { CreditControlDto } from '@ansvar-sakerhet/api-client';
import { createFrameCompanyCreditControl } from '../../../../../../traits/api/frameCompany/createCreditControl';

const exists = (value: string | null) => {
    return value && value.length > 0;
}

const isAtRisk = (sni: string | null) => {
    const risk = ['41', '42', '43', '49', '55', '56', '68', '81', '87']

    if (!sni) {
        return false;
    }

    return risk.filter(r => r === sni.substring(0, 2)).length > 0;
}

const getIcon = (value: string | null) => {
    switch (value) {
        case '1':
            return '✅';
        case '0':
            return '⚠️';
        case '-1':
            return '‼️';
        default:
            return value;
    }
}
export const CreditControlPage = ({ creditControl, frameCompanyId }: {
    creditControl: CreditControlDto;
    frameCompanyId: string;
}) => {

    const createCreditControl = createFrameCompanyCreditControl();

    if (!creditControl) {
        return (
            <div>
                <S.Margin $top="m" />
                <S.Button onClick={() => {
                    createCreditControl.mutate(frameCompanyId);
                }}>Beställ kreditkontroll</S.Button>
            </div>)
    }

    return (
        <>
            <S.Margin $top="m" />
            <div style={{ backgroundColor: 'white', padding: 10 }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <S.H5>Företagsuppgifter</S.H5>
                        <S.Margin $top="s" />
                        <S.Title>Kreditkontroll skapad:</S.Title><S.B3>{(new Date(creditControl.createdAt).toLocaleDateString())}</S.B3>
                        <S.Margin $top="m" />
                        <S.Title>Företagsnamn:</S.Title><S.B3>{creditControl.name} {exists(creditControl.earlierName) ? `(${creditControl.earlierName})` : null}</S.B3>
                        <S.Margin $top="s" />
                        <S.Title>Address:</S.Title><S.B3>{creditControl.adressCO}</S.B3>
                        <S.Margin $top="s" />
                        <S.Title>Postkod:</S.Title><S.B3>{creditControl.zipCode}</S.B3>
                        <S.Margin $top="s" />
                        <S.Title>Stad:</S.Title><S.B3>{creditControl.town}</S.B3>
                    </div>
                    <div style={{ width: '50%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column', margin: 'auto' }}>
                            <S.H4>Kreditscore</S.H4>
                            <S.Margin $top="s" />
                            <S.H1>{creditControl.rating}</S.H1>
                            <S.Margin $top="s" />
                            <S.Title>{creditControl.ratingDescription}</S.Title>
                            <S.Title style={{color: 'red'}}>{isAtRisk(creditControl.sniCode) ? 'Riskbransch' : ''}</S.Title>
                        </div>

                    </div>
                </div>
                <hr />
                <S.H5>Kommentarer</S.H5>
                <S.Margin $top="m" />
                {
                    creditControl.Comment && creditControl.Comment.sort((a, b) => {
                        if(!a.commentType || !b.commentType){
                            return 0
                        }

                        return a.commentType.localeCompare(b.commentType);
                    }).map((comment, index) => (
                        <>
                            <S.Margin $top="m" />
                            <S.Flex $gap={5} key={index}>
                                <S.Title>{comment.commentText} {getIcon(comment.commentType)}</S.Title>
                            </S.Flex>
                        </>
                    ))
                }
                <hr />
                <S.H5>Verksamhet</S.H5>
                <S.Margin $top="m" />
                <S.Flex $gap={5}>
                    <S.Title>Bolagsform:</S.Title><S.B3>{creditControl.companyType}, {creditControl.companyTypeText}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Bolagsstatus:</S.Title><S.B3>{creditControl.companyActiveText}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Reg.datum:</S.Title><S.B3>{creditControl.registrationDate}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Organisationsnummer:</S.Title><S.B3>{creditControl.orgNumber}</S.B3>
                </S.Flex>
                {
                    creditControl.vatNumber && (
                        <>
                            <S.Margin $top="s" />
                            <S.Flex $gap={5}>
                                <S.Title>Momsreg.nr:</S.Title><S.B3>{creditControl.vatNumber}</S.B3>
                            </S.Flex>
                        </>)
                }

                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Registrerad för moms:</S.Title><S.B3>{creditControl.vat} {creditControl.vatStart}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>F-Skatt:</S.Title><S.B3>{creditControl.fTax} {creditControl.fTaxStart}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Registrerad arbetsgivare:</S.Title><S.B3>{creditControl.empTax}</S.B3>
                </S.Flex>
                <S.Margin $top="m" />
                <S.Flex $gap={5}>
                    <S.Title>Bransch:</S.Title><S.B3>{creditControl.sniCode} <span style={{color: 'red'}}>{isAtRisk(creditControl.sniCode) ? 'Riskbransch' : ''}</span></S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Branschbeskrivning:</S.Title><S.B3>{creditControl.sniText}</S.B3>
                </S.Flex>
                <S.Margin $top="m" />
                <S.Flex $gap={5}>
                    <S.Title>Antal anställda:</S.Title><S.B3>{creditControl.numberEmployees}</S.B3>
                </S.Flex>
                <hr />
                <S.H5>Finansiella tal</S.H5>
                <S.Margin $top="m" />
                <S.Flex $gap={5}>
                    <S.Title>Aktiekapital:</S.Title><S.B3>{creditControl.shareCapital}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Senaste bokföringsperiod:</S.Title><S.B3>{creditControl.latestAccountPeriod}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Omsättningsintervall:</S.Title><S.B3>{creditControl.turnoverInterval}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Soliditet:</S.Title><S.B3>{creditControl.soliditetRating}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Nettomarginal:</S.Title><S.B3>{creditControl.intjaningsformaga}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Kassalikviditet:</S.Title><S.B3>{creditControl.quickRatio}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Skuldsättningsgrad:</S.Title><S.B3>{creditControl.skuldSattningsgrad}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Balanslikviditet:</S.Title><S.B3>{creditControl.balanslikviditet}</S.B3>
                </S.Flex>
                <hr />
                <S.H5>Anmärkningar</S.H5>
                <S.Margin $top="m" />
                <S.Flex $gap={5}>
                    <S.Title>Antal betalningsanmärkningar:</S.Title><S.B3>{creditControl.numberAnm}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Antal skuldsaldo A-mål:</S.Title><S.B3>{creditControl.antalAnmADebt}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Antal skuldsaldo E-mål:</S.Title><S.B3>{creditControl.antalAnmEDebt}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Företag på varningslista:</S.Title><S.B3>{creditControl.companyWarningList === '1' ? 'Ja' : 'Nej'}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Styrelsemedlemmar på varningslista:</S.Title><S.B3>{creditControl.directorWarningList === '1' ? 'Ja' : 'Nej'}</S.B3>
                </S.Flex>
                <S.Margin $top="m" />
                {
                    exists(creditControl.taxRespiteAmount) && (
                        <S.Flex $gap={5}>
                            <S.Title>Tillfälligt anstånd med skattebetalning:</S.Title><S.B3>{creditControl.taxRespiteAmount} - {creditControl.taxRespiteDate}</S.B3>
                        </S.Flex>
                    )
                }

                {
                    creditControl.bankruptcyText && creditControl.bankruptcyText.length > 0 && (
                        <>
                            <S.Margin $top="s" />
                            <S.Flex $gap={5}>
                                <S.Title>Konkurs:</S.Title><S.B3>{creditControl.bankruptcyText} ({creditControl.bankruptcyDate})</S.B3>
                            </S.Flex>
                        </>
                    )
                }
                <hr />
                <S.H5>Riskindikatorer</S.H5>
                <S.Margin $top="m" />
                <S.Flex $gap={5}>
                    <S.Title>Riskprognos:</S.Title><S.B3>{creditControl.riskPrognosis}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Företagsnamn ändrat:</S.Title><S.B3>{getText(creditControl.riskNameChange)}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Address ändrad:</S.Title><S.B3>{getText(creditControl.riskAdressChange)}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Styrelse ändrad:</S.Title><S.B3>{getText(creditControl.riskBoardChange)}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Status på revisor:</S.Title><S.B3>{getAuditText(creditControl.riskAuditChange)}</S.B3>
                </S.Flex>
                <S.Margin $top="s" />
                <S.Flex $gap={5}>
                    <S.Title>Aktivitetstext ändrad:</S.Title><S.B3>{getText(creditControl.riskActivityChange)}</S.B3>
                </S.Flex>

                <hr />
                <S.H5>Styrelse</S.H5>
                <S.Margin $top="m" />
                {
                    creditControl.Directors && creditControl.Directors.map((director, index) => (
                        <>
                            <S.Margin $top="s" />
                            <S.Flex $gap={5} key={index}>
                                <S.Title>{director.name}:</S.Title><S.B3>{director.function}</S.B3>
                            </S.Flex>
                        </>
                    ))
                }
                {
                    exists(creditControl.closestGroupMotherOrg) && (
                        <>
                            <hr />
                            <S.H5>Koncern</S.H5>
                            <S.Margin $top="m" />
                            <S.Flex $gap={5}>
                                <S.Title>Moderbolag:</S.Title><S.B3>{creditControl.closestGroupMotherOrg} - {creditControl.closestGroupMotherOrgName}</S.B3>
                            </S.Flex>
                            <S.Margin $top="s" />
                            <S.Flex $gap={5}>
                                <S.Title>Koncernmoder:</S.Title><S.B3>{creditControl.topGroupMotherOrgName}</S.B3>
                            </S.Flex>
                        </>
                    )
                }
                <div>
                    <S.Margin $top="l" />
                    <S.Button onClick={() => {
                        createCreditControl.mutate(frameCompanyId);
                    }}>Beställ ny kreditkontroll</S.Button>
                </div>

            </div>

        </>

    );
};

const getAuditText = (text: string | null) => {
    switch (text) {
        case '1':
            return 'Har revisor ✅';
        case '2':
            return 'Förbehåll om att inte ha revisor ⚠️';
        case '3':
            return 'Revisor har lämnat på egen begäran ‼️';
        default:
            return text;
    }
}

const getText = (text: string | null) => {
    switch (text) {
        case '1':
            return 'Har inte ändrats inom 6 månader ✅';
        case '2':
            return 'Har ändrats inom 3-6 månader ⚠️';
        case '3':
            return 'Har ändrats inom 3 månader ‼️';
        default:
            return text;
    }
}
