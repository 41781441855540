import React from 'react';
import { S } from './Layout.styles';
import { Header } from '../Header/Header';
import { Main } from '../Main';

export const Layout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <S.LayoutContainer>
      <Header />
      <Main>{props.children}</Main>
    </S.LayoutContainer>
  );
};
