/**
 * Variable that describes which fields are meant to be taken into account when
 * calculating the deviations, and their possible fixes. Helps keep types in
 * order and makes sure that if new fields are added, they must be described
 * whether or not to be taken into account when calculating the deviations,
 * also where the matching results can be found.
 * See its usage for more detail on how it may be used.
 */

import {
  ControlWithCounterMeasures,
  DeviationCounterMeasureTypeEnum,
} from '@ansvar-sakerhet/api-client';

type B = {
  [key in keyof ControlWithCounterMeasures as ControlWithCounterMeasures[key] extends
    | string
    | null
    ? key
    : never]: null;
};
export type ControlKeys = keyof B;

export type UsageDescription = {
  shouldHandle: true;
  resultsKey: ControlKeys;
  counterMeasureType: DeviationCounterMeasureTypeEnum;
  actionsKey: ControlKeys;
};

export const DEVIATION_FIELDS: {
  [key in keyof Partial<ControlWithCounterMeasures>]: UsageDescription;
} = {
  shellProtectionActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'shellProtectionResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.ShellProtection,
    actionsKey: 'shellProtectionActions',
  },

  workEnvironmentActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'workEnvironmentResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.WorkEnvironment,
    actionsKey: 'workEnvironmentActions',
  },

  workRegisterActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'workRegisterResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.WorkRegister,
    actionsKey: 'workRegisterActions',
  },

  preApprovedSubcontractorActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'preApprovedSubcontractorResult',
    counterMeasureType:
      DeviationCounterMeasureTypeEnum.PreApprovedSubcontractor,
    actionsKey: 'preApprovedSubcontractorActions',
  },

  id06DeviationsActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'id06DeviationsResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.Id06Deviations,
    actionsKey: 'id06DeviationsActions',
  },

  identificationActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'identificationResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.Identification,
    actionsKey: 'identificationActions',
  },

  workPermitActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'workPermitResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.WorkPermit,
    actionsKey: 'workPermitActions',
  },

  secondmentRegulationsActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'secondmentRegulationsResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.Secondment,
    actionsKey: 'secondmentRegulationsActions',
  },

  intoxicationActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'intoxicationResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.Intoxication,
    actionsKey: 'intoxicationActions',
  },

  safetyEquipmentActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'safetyEquipmentResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.SafetyEquipment,
    actionsKey: 'safetyEquipmentActions',
  },

  otherActionsNeeded: {
    shouldHandle: true,
    resultsKey: 'otherResult',
    counterMeasureType: DeviationCounterMeasureTypeEnum.Other,
    actionsKey: 'otherActions',
  },
} as const;
