import { useApi } from '../useApi';

export function useGetFiles() {
  const getApi = useApi();

  const getFile = async (fileUrl: string) => {
    const api = await getApi();

    const res = await api.filesGetSasToken(fileUrl);
    return res.data;
  };

  return getFile;
}
