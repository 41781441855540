import React, { useState } from 'react';
import { S } from './NonResolvedAction.styles';
import { Checkbox } from '../../../../../../components/Checkbox';
import {
  deviationCounterMeasureFormValues,
  useCreateDeviationCounterMeasureForm,
} from './hooks/useCreateDeviationCounterMeasureForm';
import { TextArea } from '../../../../../../components/TextArea';
import { NonResolvedDeviation } from '../../hooks/useDeviations';
import { DeviationCounterMeasureTypeEnum } from '@ansvar-sakerhet/api-client';
import { useCreateDeviationCounterMeasure } from '../../../../../../traits/api/deviationCounterMeasure/useCreateDeviationCounterMeasure';

export const NonResolvedAction = ({
  deviation,
}: {
  deviation: NonResolvedDeviation;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useCreateDeviationCounterMeasureForm();
  const { mutate } = useCreateDeviationCounterMeasure();
  const onSubmit = (data: deviationCounterMeasureFormValues) =>
    mutate({ ...data, controlId: deviation.controlId, type: deviation.type });

  return (
    <S.Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Trigger
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          $isExpanded={isExpanded}
        >
          <S.AnimatedIcon src="ChevronDown" />
          <S.RecommendationHeading>
            {getUserFriendlyDeviationType(deviation.type)}
          </S.RecommendationHeading>
        </S.Trigger>
        {isExpanded && (
          <S.Content>
            <S.ResultsAndActionsSection>
              <div>
                <S.ContentHeading>Resultat</S.ContentHeading>
                <S.ContentText>{deviation.result}</S.ContentText>
              </div>
              <div>
                <S.ContentHeading>Åtgärder</S.ContentHeading>
                <S.ContentText>{deviation.action}</S.ContentText>
              </div>
            </S.ResultsAndActionsSection>
            <S.CounterMeasuresSection>
              <Checkbox
                error={errors.fixed}
                label="Avvikelse åtgärdad"
                {...register('fixed')}
              />
              <TextArea
                error={errors.fixDescription}
                label="Beskriv hur detta har åtgärdats"
                {...register('fixDescription')}
              />

              <S.ContentInput
                error={errors.fixedAt}
                type="date"
                label="Datum för åtgärd"
                {...register('fixedAt')}
              ></S.ContentInput>
              <S.ContentInput
                error={errors.fixedBy}
                label="Åtgärd genomförd av"
                {...register('fixedBy')}
              />
              <S.FlexEnd>
                <S.Button type="submit">Spara</S.Button>
              </S.FlexEnd>
            </S.CounterMeasuresSection>
          </S.Content>
        )}
      </form>
    </S.Container>
  );
};

function getUserFriendlyDeviationType(type: DeviationCounterMeasureTypeEnum) {
  switch (type) {
    case DeviationCounterMeasureTypeEnum.Id06Deviations:
      return 'Brott mot ID06 regelverk';
    case DeviationCounterMeasureTypeEnum.Identification:
      return 'Avsaknad av giltig legitimation';
    case DeviationCounterMeasureTypeEnum.Intoxication:
      return 'Tecken på onykterhet och drogpåverkan';
    case DeviationCounterMeasureTypeEnum.Other:
      return 'Övrig kontrollverksamhet';
    case DeviationCounterMeasureTypeEnum.PreApprovedSubcontractor:
      return 'Avstämning mot förgodkända UE';
    case DeviationCounterMeasureTypeEnum.SafetyEquipment:
      return 'Personlig säkerhetsutrustning';
    case DeviationCounterMeasureTypeEnum.Secondment:
      return 'Utstationeringsregelverket';
    case DeviationCounterMeasureTypeEnum.ShellProtection:
      return 'Skalskydd/inhägnad';
    case DeviationCounterMeasureTypeEnum.WorkEnvironment:
      return 'Arbetsmiljö';
    case DeviationCounterMeasureTypeEnum.WorkPermit:
      return 'Kontroll av arbetstillstånd och uppehållshandlingar';
    case DeviationCounterMeasureTypeEnum.WorkRegister:
      return 'Elektronisk personalliggare (EPL) – Skatteförfarandelagen';
  }
}
