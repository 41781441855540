// Typography
import { H1 } from './typography/H1';
import { H2 } from './typography/H2';
import { H3 } from './typography/H3';
import { H4 } from './typography/H4';
import { H5 } from './typography/H5';
import { H6 } from './typography/H6';
import { B1 } from './typography/B1';
import { B2 } from './typography/B2';
import { B3 } from './typography/B3';
import { B4 } from './typography/B4';
import { D1 } from './typography/D1';
import { D2 } from './typography/D2';

// Buttons
import { Button } from './buttons/Button';

// Selects

import { Select } from './selects/Select';

// Icons
import { Icon } from './icons/Icon';

// Layout
import { Margin } from './layout/Margin';
import { Flex } from './layout/Flex';
import { HR } from './layout/HR';

//Inputs
import { Textarea } from './inputs/Textarea';
import { Label } from './inputs/Label';

//Table
import { TWrapper } from './table/TWrapper';
import { Table } from './table/Table';
import { THead } from './table/THead';
import { THeadRow } from './table/THeadRow';
import { THeadCell } from './table/THeadCell';
import { TBody } from './table/TBody';
import { TBodyRow } from './table/TBodyRow';
import { TBodyCell } from './table/TBodyCell';

//Alternative table
import { Table as AltTable } from './alternative-table/Table';
import { THead as AltTHead } from './alternative-table/THead';
import { THeadRow as AltTHeadRow } from './alternative-table/THeadRow';
import { THeadCell as AltTHeadCell } from './alternative-table/THeadCell';
import { TBody as AltTBody } from './alternative-table/TBody';
import { TBodyRow as AltTBodyRow } from './alternative-table/TBodyRow';
import { TBodyCell as AltTBodyCell } from './alternative-table/TBodyCell';

export const UIElements = {
  HR,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  B1,
  B2,
  B3,
  B4,
  D1,
  D2,
  Button,
  Icon,
  Margin,
  Flex,
  TWrapper,
  Table,
  THead,
  THeadRow,
  THeadCell,
  TBody,
  TBodyRow,
  TBodyCell,
  AltTable,
  AltTHead,
  AltTHeadRow,
  AltTHeadCell,
  AltTBody,
  AltTBodyRow,
  AltTBodyCell,
  Textarea,
  Label,
  Select,
};
