import { UIElements } from '@ansvar-sakerhet/ui';

import styled from 'styled-components';

const CreateFrameCompany = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BackButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
`;

export const S = {
  ...UIElements,
  CreateFrameCompany,
  Container,
  BackButton,
};
