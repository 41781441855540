import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { projectsKeys } from './projects.key';

export function useGetMyProject(projectId: string) {
  const getApi = useApi();

  const getProject = async () => {
    const api = await getApi();

    const res = await api.projectsFindOneOfMine(projectId);
    return res.data;
  };

  const query = useQuery({
    queryKey: projectsKeys.findMyProject(projectId),
    queryFn: getProject,
    keepPreviousData: true,
  });

  return {
    ...query,
  };
}
