import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const CenterSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const Wrapper = styled.div`
  height: 40vw;
  width: 50vw;
  max-width: 640px;
  max-height: 480px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const S = {
  ...UIElements,
  CenterSection,
  Wrapper,
  TextContainer,
};
