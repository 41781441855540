import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './news.keys';

export function useGetAllNews(take?: number, skip?: number) {
  const getApi = useApi();

  const getNews = async () => {
    const api = await getApi();

    const res = await api.newsFindAll(take, skip);

    return res;
  };

  const query = useQuery({
    queryKey: keys.findAll(take, skip),
    queryFn: getNews,
  });

  return {
    ...query,
    data: query.data?.data,
    count: query.data?.headers['x-total-count'],
  };
}
