import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { CreateFaqDtoFaqCategoryEnum } from '@ansvar-sakerhet/api-client';

export function useGetFaq() {
  const getApi = useApi();

  const getFaq = async () => {
    const api = await getApi();

    const res = await api.faqsFindAll();

    return res.data;
  };
  const query = useQuery({ queryFn: getFaq, queryKey: ['faqs'] });
  const customerPortalFaq = query?.data?.filter(
    (item) => item.faqCategory === CreateFaqDtoFaqCategoryEnum.CustomerPortal
  );
  return { ...query, customerPortalFaq };
}
