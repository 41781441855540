import { ControlWithCounterMeasures } from '@ansvar-sakerhet/api-client';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { useMemo } from 'react';

export const useLatestControlTableData = (
  control: ControlWithCounterMeasures | null
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Platschef',
        accessor: 'siteManager',
      },
      {
        Header: 'Huvudentreprenör',
        accessor: 'mainContractor',
      },
      {
        Header: 'Senaste arbetsplatskontrollen',
        accessor: 'date',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    return [
      {
        siteManager: control?.siteManager ?? '-',
        mainContractor: control?.mainContractor ?? '-',
        date: control?.date
          ? format(new Date(control.date), 'd MMMM yyyy', { locale: sv })
          : '-',
      },
    ];
  }, [control]);

  return { columns, data };
};
