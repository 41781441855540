import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  tr:first-child td:first-child {
    border-top-left-radius: 4px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 4px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }
`;
