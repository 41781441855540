import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { S } from './MyProject.styles';
import {
  UpdateProjectProjectSizeEnum,
  UpdateProjectProjectTypeEnum,
} from '@ansvar-sakerhet/api-client';
type Props = {
  onCancel: () => void;
  projectType: 'Bank' | 'CSRD' | 'Custom' | undefined;
  projectSize: 'Small' | 'Medium' | 'Large' | undefined;
  custom: number;
  updateProjectSize: (size: UpdateProjectProjectSizeEnum) => void;
  updateProjectType: (type: UpdateProjectProjectTypeEnum) => void;
  updateCustom: (custom: number) => void;
  save: () => void;
};

export const EditProjectSettings: React.FC<Props> = ({
  onCancel,
  projectType,
  projectSize,
  custom,
  updateProjectSize,
  updateProjectType,
  updateCustom,
  save,
}) => {
  return (
    <AlertDialog.Portal>
      <S.Overlay>
        <S.Wrapper>
          <S.CancelButton onClick={onCancel}>
            <S.Icon src="Close" width={24} height={24} />
          </S.CancelButton>

          <S.ContentContainer>
            <AlertDialog.Title asChild>
              <S.H5>Hur stort är projektet?</S.H5>
            </AlertDialog.Title>
            <S.ButtonContainer>
              <S.Button
                $outlined={
                  !(projectSize === UpdateProjectProjectSizeEnum.Small)
                }
                onClick={() =>
                  updateProjectSize(UpdateProjectProjectSizeEnum.Small)
                }
              >
                Litet
              </S.Button>
              <S.Button
                $outlined={
                  !(projectSize === UpdateProjectProjectSizeEnum.Medium)
                }
                onClick={() =>
                  updateProjectSize(UpdateProjectProjectSizeEnum.Medium)
                }
              >
                Mellan
              </S.Button>
              <S.Button
                $outlined={
                  !(projectSize === UpdateProjectProjectSizeEnum.Large)
                }
                onClick={() =>
                  updateProjectSize(UpdateProjectProjectSizeEnum.Large)
                }
              >
                Stort
              </S.Button>
            </S.ButtonContainer>
            <S.Margin $vertical="s" />
            <AlertDialog.Title asChild>
              <S.H5>Vilken standard vill du följa?</S.H5>
            </AlertDialog.Title>
            <S.ButtonContainer>
              <S.Button
                $outlined={!(projectType === UpdateProjectProjectTypeEnum.Bank)}
                onClick={() =>
                  updateProjectType(UpdateProjectProjectTypeEnum.Bank)
                }
              >
                Bankinitativet
              </S.Button>
              <S.Button
                $outlined={!(projectType === UpdateProjectProjectTypeEnum.Csrd)}
                onClick={() =>
                  updateProjectType(UpdateProjectProjectTypeEnum.Csrd)
                }
              >
                CSRD
              </S.Button>
              <S.Button
                $outlined={
                  !(projectType === UpdateProjectProjectTypeEnum.Custom)
                }
                onClick={() =>
                  updateProjectType(UpdateProjectProjectTypeEnum.Custom)
                }
              >
                Egen
              </S.Button>
            </S.ButtonContainer>
            {projectType === UpdateProjectProjectTypeEnum.Custom && (
              <S.Section style={{ textAlign: 'center' }}>
                <S.H5>Antal månader mellan kontroller</S.H5>
                <S.Margin $vertical="s" />
                <input
                  style={{}}
                  type="number"
                  onChange={(e) => updateCustom(Number(e.target.value))}
                  value={custom}
                />
              </S.Section>
            )}

            <S.ButtonContainer>
              <AlertDialog.Cancel asChild>
                <S.Button $outlined onClick={onCancel}>
                  Avbryt
                </S.Button>
              </AlertDialog.Cancel>

              <AlertDialog.Action asChild>
                <S.Button onClick={() => save()}>Spara</S.Button>
              </AlertDialog.Action>
            </S.ButtonContainer>
          </S.ContentContainer>
        </S.Wrapper>
      </S.Overlay>
    </AlertDialog.Portal>
  );
};
