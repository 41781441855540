import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { toast } from '../../../components/Toast';

export function createFrameCompanyCreditControl() {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const createCreditControl = async (
    frameCompanyId: string
  ) => {
    const api = await getApi();

    const res = await api.frameCompanyCreateCreditControl({
        frameCompanyId,
    });

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Order har lagts');
    queryClient.invalidateQueries({ queryKey: keys.all });
  };

  const onError = () => {
    toast.error('Något gick fel');
  };

  const mutation = useMutation(createCreditControl, {
    onSuccess,
    onError,
  });

  return mutation;
}
