import { zodResolver } from '@hookform/resolvers/zod';
import { isValid, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const createDeviationCounterMeasureSchema = z.object({
  fixed: z.boolean().refine((val) => val === true, {
    message: 'Åtgärd måste vara åtgärdad för att sparas.',
  }),
  fixDescription: z.string().min(1, 'Vänligen ange hur det har åtgärdats.'),
  fixedAt: z.string().refine((val) => isValid(parseISO(val)), {
    message: 'Vänligen ange ett giltigt datum.',
  }),
  fixedBy: z.string().min(1, 'Vänligen ange vem som genomförde åtgärden.'),
});

export type deviationCounterMeasureFormValues = z.infer<
  typeof createDeviationCounterMeasureSchema
>;

export const useCreateDeviationCounterMeasureForm = () => {
  const methods = useForm<deviationCounterMeasureFormValues>({
    mode: 'onTouched',
    resolver: zodResolver(createDeviationCounterMeasureSchema),
    defaultValues: {
      fixed: false,
      fixDescription: '',
      fixedAt: undefined,
      fixedBy: '',
    },
  });

  return methods;
};
