import React from 'react';
import { S } from './DownloadReportButton.styles';
import { toast } from '../../../../../../../components/Toast';
import { useGetFile } from '../../../../../../../traits/api/controls/useGetFile';
export const DownloadReportButton = ({
  downloadUrl,
}: {
  downloadUrl: string;
}) => {
  const getFile = useGetFile(downloadUrl);

  const startDownload = async () => {
    if (!downloadUrl) {
      return toast.error('Fil kunde inte hittas');
    }
    const file = await getFile();
    const link = document.createElement('a');
    link.href = file.path;
    link.download = 'rapport';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.DownloadButton onClick={startDownload}>
      <S.IconWithBg src="Draft" /> Ladda ner rapport
    </S.DownloadButton>
  );
};
