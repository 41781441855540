export const IconType = {
  Add: require('../assets/icons/add.svg'),
  Alert: require('../assets/icons/alert.svg'),
  ArrowDropDown: require('../assets/icons/arrow-drop-down.svg'),
  ArrowDropUp: require('../assets/icons/arrow-drop-up.svg'),
  Bolt: require('../assets/icons/bolt.svg'),
  BoltSlash: require('../assets/icons/bolt-slash.svg'),
  Calendar: require('../assets/icons/calendar.svg'),
  CameraPlus: require('../assets/icons/camera-plus.svg'),
  Chart: require('../assets/icons/chart.svg'),
  Check: require('../assets/icons/check.svg'),
  CheckCircle: require('../assets/icons/check-circle.svg'),
  ChevronDown: require('../assets/icons/chevron-down.svg'),
  ChevronLeft: require('../assets/icons/chevron-left.svg'),
  ChevronRight: require('../assets/icons/chevron-right.svg'),
  ChevronUp: require('../assets/icons/chevron-up.svg'),
  Close: require('../assets/icons/close.svg'),
  DataCheck: require('../assets/icons/data-check.svg'),
  Diagram: require('../assets/icons/diagram.svg'),
  Draft: require('../assets/icons/draft.svg'),
  Edit: require('../assets/icons/edit.svg'),
  FilledQuestion: require('../assets/icons/filled-question.svg'),
  File: require('../assets/icons/file.svg'),
  HelmetGuy: require('../assets/icons/helmet-guy.svg'),
  Info: require('../assets/icons/info.svg'),
  Leaderboard: require('../assets/icons/leaderboard.svg'),
  Lists: require('../assets/icons/lists.svg'),
  Logo: require('../assets/icons/logo.svg'),
  Plus: require('../assets/icons/plus.svg'),
  News: require('../assets/icons/news.svg'),
  QRCode: require('../assets/icons/qrcode.svg'),
  Question: require('../assets/icons/question.svg'),
  Rules: require('../assets/icons/rules.svg'),
  Search: require('../assets/icons/search.svg'),
  Setting: require('../assets/icons/setting.svg'),
  Sort: require('../assets/icons/sort.svg'),
  Spinner: require('../assets/icons/spinner.svg'),
  PaperStatistics: require('../assets/icons/paper-statistics.svg'),
  Pen: require('../assets/icons/pen.svg'),
  Upload: require('../assets/icons/upload.svg'),
  UserCircle: require('../assets/icons/user-circle.svg'),
  Warning: require('../assets/icons/warning.svg'),
  Contract: require('../assets/icons/contract.svg'),
  Trash: require('../assets/icons/trash.svg'),
  Logout: require('../assets/icons/logout.svg'),
  Validation: require('../assets/icons/validation.svg'),
  Download: require('../assets/icons/download.svg'),
};

export type IconType = keyof (typeof IconType)[keyof typeof IconType];
