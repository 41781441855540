import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Error = styled.div`
  padding: 4px;
`;

export const S = {
  ...UIElements,
  Wrapper,
  Label,
  Error,
};
