import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const LoginContainer = styled.div`
  display: flex;
`;

const BackgroundContainer = styled.div`
  width: 50vw;
  height: 100vh;
  padding: ${({ theme }) => theme.spacing.s};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.colors.secondary};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      #00000099 0%,
      #00000033 50%,
      transparent 100%
    );
    z-index: 1;
  }
`;

const ContentContainer = styled.div`
  height: 100vh;
  min-width: 50vw;
  padding: ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.colors.white}cc;
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 372px;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const Version = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  color: white;
  margin: 16px;
  opacity: 0.3;
  z-index: 1;
`;

export const S = {
  ...UIElements,
  Center,
  LoginContainer,
  BackgroundContainer,
  ContentContainer,
  BlockContainer,
  Version,
};
