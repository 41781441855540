import React from 'react';
import Router from './traits/routes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '@ansvar-sakerhet/ui/src/core/theme';
import { Auth0Provider } from '@auth0/auth0-react';
import { GlobalStyles } from '@ansvar-sakerhet/ui/src/core/global';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppConfigContext, useAppConfig } from './config/hooks/useAppConfig';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import se from 'date-fns/locale/sv';
import 'react-quill/dist/quill.snow.css';

import 'animate.css';
import './App.css';

const queryClient = new QueryClient();

export default function App() {
  const app = useAppConfig();

  return (
    <AppConfigContext.Provider value={app}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={se}>
          <GlobalStyles />

          <ThemeProvider theme={defaultTheme}>
            <Toaster position="top-right" />

            <Auth0Provider
              key={app.config.AUTH0_DOMAIN}
              domain={app.config.AUTH0_DOMAIN}
              clientId={app.config.AUTH0_CLIENT_ID}
              authorizationParams={{
                scope: 'openid profile email',
                redirect_uri: window.location.origin,
                audience: app.config.AUTH0_AUDIENCE,
              }}
            >
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </Auth0Provider>
          </ThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </AppConfigContext.Provider>
  );
}
