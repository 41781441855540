import { FindAllControlStatisticsResponse } from '@ansvar-sakerhet/api-client';
import { useMemo } from 'react';

const addPercentageSymbol = (value?: number | null) => {
  if (value === null || value === undefined) {
    return '-';
  }

  return `${value}%`;
};

export const useOverviewStatisticsTableData = (
  statistics: FindAllControlStatisticsResponse | undefined
) => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Statistik från samtliga arbetsplatskontroller',
        accessor: 'statisticsDescription',
      },
      {
        Header: 'Senaste kontrollen',
        accessor: 'latestControl',
      },
      {
        Header: 'Totalt för projektet',
        accessor: 'projectTotal',
      },
      {
        Header: 'Bolaget i sin helhet',
        accessor: 'clientTotal',
      },
      {
        Header: 'I landet',
        accessor: 'countryTotal',
      },
    ] as const;
  }, []);

  const data = useMemo(() => {
    if (!statistics) return [];
    return [
      {
        statisticsDescription:
          'Kontrollerat och inloggade i elektronisk personalliggare (EPL)',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfCheckedInAndControlledPersonnel
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfCheckedInAndControlledPersonnel
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfCheckedInAndControlledPersonnel
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfCheckedInAndControlledPersonnel
        ),
      },
      {
        statisticsDescription: 'Kontrollerad, ej inloggade',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfAdditionalPersonnel
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfAdditionalPersonnel
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfAdditionalPersonnel
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfAdditionalPersonnel
        ),
      },
      {
        statisticsDescription:
          'Inloggade i elektronisk personalliggare (EPL), ej funna',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfNotControlledPersonnel
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfNotControlledPersonnel
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfNotControlledPersonnel
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfNotControlledPersonnel
        ),
      },
      {
        statisticsDescription: 'Avvikelser mot ID06 regelverk',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfPersonnelWithIdCardDeviations
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithIdCardDeviations
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithIdCardDeviations
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithIdCardDeviations
        ),
      },
      {
        statisticsDescription: 'Safe Construction Training saknas',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control
                .percentageOfPersonnelWithoutSafeConstructionTraining
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project
            .percentageOfPersonnelWithoutSafeConstructionTraining
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutSafeConstructionTraining
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutSafeConstructionTraining
        ),
      },
      {
        statisticsDescription: 'Avsaknad av giltig legitimation',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfPersonnelWithInvalidIdentification
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithInvalidIdentification
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithInvalidIdentification
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithInvalidIdentification
        ),
      },
      {
        statisticsDescription:
          'Personlig skyddsutrustning, avvikelse mot Arbetsmiljöverkets säkerhetsföreskrifter',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfPersonnelWithoutSafetyEquipment
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithoutSafetyEquipment
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutSafetyEquipment
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutSafetyEquipment
        ),
      },
      {
        statisticsDescription:
          'Upphålls- och arbetstillstånd saknas (AT/UT/PUT/AT-UND)',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfPersonnelWithoutWorkPermit
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithoutWorkPermit
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutWorkPermit
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutWorkPermit
        ),
      },

      {
        statisticsDescription: 'A1-intyg saknas',
        latestControl: addPercentageSymbol(
          statistics.control
            ? statistics.control.percentageOfPersonnelWithoutCertificate
            : 0
        ),
        projectTotal: addPercentageSymbol(
          statistics.project.percentageOfPersonnelWithoutCertificate
        ),
        clientTotal: addPercentageSymbol(
          statistics.client.percentageOfPersonnelWithoutCertificate
        ),
        countryTotal: addPercentageSymbol(
          statistics.all.percentageOfPersonnelWithoutCertificate
        ),
      },
    ];
  }, [statistics]);

  return { columns, data };
};
