import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';


export function useGetClient() {
  const getApi = useApi();

  const getClient = async () => {
    const api = await getApi();

    const res = await api.clientsGetClient();
    return res.data;
  };

  const query = useQuery({
    queryKey: ['q'],
    queryFn: getClient,
    keepPreviousData: true,
  });

  return {
    ...query,
  };
}
