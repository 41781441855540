import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetMyProject } from '../../../../traits/api/projects/useGetMyProject';
import { useLatestControl } from './hooks/useLatestControl';
import { LatestControlTable } from './components/LatestControlTable';
import { S } from './MyProject.styles';
import { Icon } from '@ansvar-sakerhet/ui/src/icons/Icon';
import { Loading } from '../../../../components/Loading';
import { useDeviations } from './hooks/useDeviations';
import { NonResolvedAction } from './components/NonResolvedAction';
import { ControlsTable } from './components/ControlsTable';
import { useContractors } from './hooks/useContractors';
import { SubcontractorsTable } from './components/SubcontractorsTable';
import { ResolvedAction } from './components/ResolvedAction';
import { OverviewStatistics } from './components/OverviewStatistics';
import { SubcontractorsStatistics } from './components/SubcontractorsStatistics';

import { BackgroundControlsStats } from '../../../news/components/Statistics/components/BackgroundControlsStats/BackgroundControlsStats';
import { ControlStats } from '../../../news/components/Statistics/components/ControlStats/ControlStats';
import { useOverviewStatistics } from '../../../../traits/api/overviewStatistics/useOverviewStatistics';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { EditProjectSettings } from './EditProjectSettings';
import {
  UpdateProjectProjectSizeEnum,
  UpdateProjectProjectTypeEnum,
} from '@ansvar-sakerhet/api-client';
import { updateProject } from '../../../../traits/api/projects/useUpdateProject';

enum DeviationsMode {
  NON_RESOLVED,
  RESOLVED,
}

export const MyProject = () => {
  const { projectId } = useParams();
  const {
    data: project,
    isLoading,
    refetch,
  } = useGetMyProject(projectId ?? '');
  const { latestControl } = useLatestControl(project);
  const { nonResolvedActions, resolvedActions } = useDeviations(project);
  const { subcontractors } = useContractors(project);
  const [deviationsMode, setDeviationsMode] = useState(
    DeviationsMode.NON_RESOLVED
  );

  const { data: stats } = useOverviewStatistics(projectId);
  const [openAlert, setOpenAlert] = useState(false);

  const navigate = useNavigate();
  const [projectType, setProjectType] = useState<
    UpdateProjectProjectTypeEnum | undefined
  >();
  const [projectSize, setProjectSize] = useState<
    UpdateProjectProjectSizeEnum | undefined
  >();
  const [custom, setCustom] = useState(project?.custom ?? 0);

  useEffect(() => {
    setProjectType(
      project?.projectType as unknown as UpdateProjectProjectTypeEnum
    );
    setProjectSize(
      project?.projectSize as unknown as UpdateProjectProjectSizeEnum
    );
    setCustom(project?.custom ?? 0);
  }, [project]);

  const a = updateProject(projectId ?? '', {
    projectSize,
    projectType,
    custom,
  });

  const handleSave = () => {
    a.mutate();
  };

  if (isLoading || !stats) return <Loading />;

  return (
    <S.Container>
      <AlertDialog.Root open={openAlert}>
        <EditProjectSettings
          projectSize={projectSize}
          projectType={projectType}
          custom={custom}
          updateProjectSize={setProjectSize}
          updateProjectType={setProjectType}
          updateCustom={setCustom}
          onCancel={() => setOpenAlert(false)}
          save={() => {
            handleSave();
            setOpenAlert(false);
          }}
        />
      </AlertDialog.Root>

      <S.Section>
        <S.BackButton onClick={() => navigate(-1)}>
          <Icon src="ChevronLeft" />
          <p>Tillbaka</p>
        </S.BackButton>
      </S.Section>
      <S.Section>
        <S.H3>Projekt {project?.name}</S.H3>
        <S.BackButton
          onClick={() => {
            setOpenAlert(true);
          }}
        >
          <p>
            <u>Projektinställningar</u>
          </p>
        </S.BackButton>
      </S.Section>

      <S.Section>
        <S.Flex $width="100%" $gap={30}>
          <BackgroundControlsStats stats={stats} />
          <ControlStats stats={stats} name={stats.clientName} />
        </S.Flex>
      </S.Section>
      <S.Section>
        <LatestControlTable latestControl={latestControl} />
      </S.Section>
      <S.Section>
        {latestControl && (
          <OverviewStatistics
            controlId={latestControl.controlId}
            inspectionId={latestControl.inspectionId}
          />
        )}
      </S.Section>
      <S.Section $maxWidth="750px">
        <S.Flex $justify="between" $align="center">
          <S.H5>Rekommendationer på åtgärder</S.H5>
          <S.InteractiveText
            onClick={() =>
              setDeviationsMode(getOppositeDeviationsMode(deviationsMode))
            }
          >
            {getSwitchDeviationsModeText(deviationsMode)}
          </S.InteractiveText>
        </S.Flex>
      </S.Section>
      <S.Section>
        {deviationsMode === DeviationsMode.NON_RESOLVED && (
          <>
            {nonResolvedActions?.length === 0 && (
              <p>Det finns för närvarande inga rekommendationer</p>
            )}
            {nonResolvedActions?.map((action, i) => (
              <NonResolvedAction
                key={`${action.type}-${i}`}
                deviation={action}
              />
            ))}
          </>
        )}
        {deviationsMode === DeviationsMode.RESOLVED && (
          <>
            {resolvedActions?.length === 0 && (
              <p>Det finns för närvarande inga åtgärdade rekommendationer</p>
            )}
            {resolvedActions?.map((action, i) => (
              <ResolvedAction key={`${action.type}-${i}`} deviation={action} />
            ))}
          </>
        )}
      </S.Section>

      <S.Section>
        <S.H5>Senaste arbetsplatskontroller</S.H5>
      </S.Section>
      <S.Section>
        {project?.controls?.length === 0 ? (
          <p>Det finns för närvarande inga kontroller</p>
        ) : (
          <ControlsTable controls={project?.controls ?? []} />
        )}
      </S.Section>

      <S.Section>
        <S.H5>Underentreprenörer</S.H5>
      </S.Section>
      <S.Section>
        {subcontractors.length === 0 ? (
          <p>Det finns för närvarande inga underentreprenörer</p>
        ) : (
          <SubcontractorsTable
            subcontractors={subcontractors}
            projectId={projectId ?? ''}
            refetch={refetch}
          />
        )}
      </S.Section>

      <S.Section>
        <S.H5>Statistik</S.H5>
      </S.Section>
      <S.Section>
        <SubcontractorsStatistics subcontractors={subcontractors} />
      </S.Section>
    </S.Container>
  );
};

function getOppositeDeviationsMode(deviationMode: DeviationsMode) {
  switch (deviationMode) {
    case DeviationsMode.NON_RESOLVED:
      return DeviationsMode.RESOLVED;
    case DeviationsMode.RESOLVED:
      return DeviationsMode.NON_RESOLVED;
  }
}

function getSwitchDeviationsModeText(deviationMode: DeviationsMode) {
  switch (deviationMode) {
    case DeviationsMode.NON_RESOLVED:
      return 'Visa åtgärdade rekommendationer';
    case DeviationsMode.RESOLVED:
      return 'Visa ej åtgärdade rekommendationer';
  }
}
