import { UIElements } from '@ansvar-sakerhet/ui';

import styled from 'styled-components';

const CreateFrameCompany = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const HiddenInput = styled.input`
  display: none;
`;

export const S = {
  CreateFrameCompany,
  HiddenInput,
  ...UIElements,
};
