export const parseFaqData = (dbAnswer: string) => {
  const emailPattern = /\[([^\]]+)\]\(mailto:([^\)]+)\)/;
  const phonePattern = /(\+\d{1,2}-\d{3}-\d{3}-\d{4})/;
  const imagePattern = /!\[[^\]]*\]\((.*?)\)/;

  const emailMatch = dbAnswer.match(emailPattern);
  const phoneMatch = dbAnswer.match(phonePattern);
  const imageMatch = dbAnswer.match(imagePattern);

  const emailLink = emailMatch ? emailMatch[0] : null;
  const emailText = emailMatch ? emailMatch[1] : null;
  const emailAddress = emailMatch ? emailMatch[2] : null;
  const phoneNumber = phoneMatch ? phoneMatch[0] : null;
  const imageText = imageMatch ? imageMatch[0] : null;
  const imageUrl = imageMatch ? imageMatch[1] : null;

  return {
    emailText,
    emailAddress,
    phoneNumber,
    imageUrl,
    imageText,
    emailLink,
  };
};
