import styled from 'styled-components';
import { Icon } from '@ansvar-sakerhet/ui/src/icons/Icon';

export const Spinner = styled(Icon)`
  animation: rotate 1.2s linear infinite;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
