import { FindAllFrameCompaniesResponse } from '@ansvar-sakerhet/api-client';
import React from 'react';
import { S } from './FrameCompaniesTable.styles';
import { useTable } from 'react-table';
import { useFrameCompaniesTableData } from './hooks/useFrameCompaniesTableData';
import { useNavigate } from 'react-router-dom';

type Props = {
  frameCompanies: FindAllFrameCompaniesResponse[];
};

export const FrameCompaniesTable = ({ frameCompanies }: Props) => {
  const data = useFrameCompaniesTableData(frameCompanies);
  const navigate = useNavigate();
  const table = useTable(data);

  if (frameCompanies.length === 0) {
    return <S.B1>Inga avtalsföretag kunde hittas. Vänligen skapa ett.</S.B1>;
  }

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                const headerData = data.columns.filter(
                  ({ accessor }) => accessor === column.id
                )[0];
                const interactiveProps = headerData?.onClick
                  ? { onClick: headerData?.onClick }
                  : {};
                const iconSrc = headerData?.iconSrc?.();

                return (
                  <S.AltTHeadCell
                    {...interactiveProps}
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                    {iconSrc && <S.CenteredIcon src={iconSrc} />}
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <S.AltTBodyCell
                      onClick={
                        cell.column.Header !== 'Rapport'
                          ? () => navigate(row.original.frameCompanyId)
                          : undefined
                      }
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer>
  );
};
