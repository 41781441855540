import React from 'react';
import { S } from './LatestControlTable.styles';
import { useLatestControlTableData } from './hooks/useLatestControlTableData';
import { useTable } from 'react-table';
import { ControlWithCounterMeasures } from '@ansvar-sakerhet/api-client';

type Props = {
  latestControl: ControlWithCounterMeasures | null;
};

export const LatestControlTable = ({ latestControl }: Props) => {
  const data = useLatestControlTableData(latestControl);
  const table = useTable(data);

  return (
    <S.TableContainer>
      <S.AltTable {...table.getTableProps()}>
        <S.AltTHead>
          {table.headerGroups.map((headerGroup) => (
            <S.AltTHeadRow
              {...headerGroup.getHeaderGroupProps()}
              key={headerGroup.getHeaderGroupProps().key}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <S.AltTHeadCell
                    {...column.getHeaderProps()}
                    key={column.getHeaderProps().key}
                  >
                    <S.TableText>{column.render('Header')}</S.TableText>
                  </S.AltTHeadCell>
                );
              })}
            </S.AltTHeadRow>
          ))}
        </S.AltTHead>

        <S.AltTBody {...table.getTableBodyProps()}>
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <S.AltTBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                {row.cells.map((cell) => {
                  return (
                    <S.AltTBodyCell
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                    >
                      {cell.render('Cell')}
                    </S.AltTBodyCell>
                  );
                })}
              </S.AltTBodyRow>
            );
          })}
        </S.AltTBody>
      </S.AltTable>
    </S.TableContainer>
  );
};
