import React from 'react';
import { S } from './HeaderDropdown.styles';
import { useUser } from '../../traits/hooks/useUser';
import { useAuth } from '../../traits/hooks/useAuth';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const HeaderDropdown: React.FC<Props> = (props) => {
  const { logout } = useAuth();
  const { user } = useUser();

  return (
    <React.Fragment>
      <S.ProfileDropdownBackground
        onClick={props.onClose}
        $isOpen={props.isOpen}
      />

      {props.isOpen && (
        <S.ProfileDropdownContainer>
          <S.AccountContainer>
            <S.H6>Konto</S.H6>

            <S.Margin $spacing="s" />

            <S.B2>{user.name}</S.B2>

            <S.Margin $spacing="xs" />

            <S.B3 $color="darkGray">{user.email}</S.B3>
          </S.AccountContainer>

          <S.Divider />

          <S.LogoutContainer onClick={logout}>
            <S.LogoutInner>
              <S.Icon src="Logout" width={22} color="secondary" />

              <S.B2 $top="xxs" $color="secondary" $weight={500}>
                Logga ut
              </S.B2>
            </S.LogoutInner>
          </S.LogoutContainer>
        </S.ProfileDropdownContainer>
      )}
    </React.Fragment>
  );
};
