import styled, { css } from 'styled-components';
import { addPxSuffix } from '../helpers/addPxSuffix';

export const Button = styled.button<{
  disabled?: boolean;
  $width?: number | string;
  $height?: number | string;
  $fontSize?: number;
  $outlined?: boolean;
}>`
  height: ${(props) => (props.$height ? addPxSuffix(props.$height) : '46px')};
  width: ${(props) => (props.$width ? addPxSuffix(props.$width) : '100%')};
  max-width: 437px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: ${(props) =>
    props.$fontSize ? addPxSuffix(props.$fontSize) : '18px'};
  cursor: pointer;
  background-color: ${({ $outlined, theme }) =>
    $outlined ? 'transparent' : theme.colors.primary};
  border-radius: 2px;

  ${({ theme, disabled, $outlined }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
          background-color: ${$outlined ? 'transparent' : theme.colors.gray};
          border: 1px solid ${theme.colors.gray};
        `
      : css`
          box-shadow: 0px 1px 6px 0px rgba(27, 27, 27, 0.05);
          border: 1px solid ${$outlined ? theme.colors.primary : 'transparent'};

          &:hover {
            background-color: ${$outlined ? theme.colors.primary : '#b49440'};
          }
        `}
`;
