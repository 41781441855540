import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const UploadFileButton = styled(UIElements.Button)`
  box-shadow: none;
  font-size: 14px;
  font-weight: 300;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const HiddenInput = styled.input`
  display: none;
`;

export const S = {
  UploadFileButton,
  HiddenInput,
  ...UIElements,
};
