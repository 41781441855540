import { SortControls } from './useGetMyProjects';

const all = ['projects'] as const;

const findMyProjects = (filters?: {
  page: number;
  sort: SortControls;
  search?: string;
}) => {
  return [...all, 'mine', { ...filters }] as const;
};

const findMyProjectsWithStatistics = (filters?: {
  page: number;
  search?: string;
}) => {
  return [...all, 'mine', { ...filters }] as const;
};

const findMyProject = (projectId: string) => {
  return [...all, 'mine', projectId] as const;
};

export const projectsKeys = {
  all,
  findMyProjects,
  findMyProjectsWithStatistics,
  findMyProject,
};
