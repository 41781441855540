import React from 'react';
import { DownloadFieldFileButton } from '../DownloadFieldFileButton/DownloadFieldFileButton';
import { S } from './FieldFile.style';
import { useReportUpload } from './useReportUpload';

type Props = { file?: { path: string }, frameCompanyId: string, type: string };

export const FieldFile = ({ file, frameCompanyId, type }: Props) => {
  if (file) return <DownloadFieldFileButton frameCompanyId={frameCompanyId} type={type} downloadUrl={file.path} />;
  const { handleUploadClick, inputRef, handleFileChange } = useReportUpload(frameCompanyId, type);

  return (
    <>
      <S.UploadFileButton onClick={(e: any) => {
        e.preventDefault()
        handleUploadClick()
      }}>
        <S.Icon src="Plus" /> Ladda upp fil
      </S.UploadFileButton>
      <S.HiddenInput
        type="file"
        ref={inputRef}
        onChange={async (ev) => {
          handleFileChange(ev)
        }}
        accept=".pdf"
      />
    </>

  );
};
