import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

@page {
  size: A4;
  margin: 0;
}

* {
  box-sizing: border-box;
}

html {
  background-color: #FFF;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
 display: block;
}

.rsw-ce b{
  font-weight: 700;
}
.rsw-ce i{
  font-style: italic;
}
body {
 line-height: 1;
}
ol, ul {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
table {
 border-collapse: collapse;
 border-spacing: 0;
}
body, #root {
    height: 100vh;
    font-family: "Calibre", sans-serif;
}

#popup > * {
  -webkit-animation: popup 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: popup 0.2s; /* Firefox < 16 */
  -ms-animation: popup 0.2s; /* Internet Explorer */
  -o-animation: popup 0.2s; /* Opera < 12.1 */
  animation: popup 0.2s;

  @keyframes popup {
    0% {
      opacity: 0;
      transform: translateY(-2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes popup {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes popup {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
}
`;
