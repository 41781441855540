import React from 'react';

import { S } from './TemporaryLogo.styles';

export const TemporaryLogoWithText: React.FC = () => {
  return (
    <S.Flex>
      <S.Icon src="HelmetGuy" width={137} height={129} />

      <S.TemporaryFlexBorder>
        <S.CustomText $color="white" $weight={400}>
          Tryggt
        </S.CustomText>

        <S.CustomText $color="white" $weight={400}>
          Vitt
        </S.CustomText>

        <S.CustomText $color="white" $weight={400}>
          Säkert
        </S.CustomText>
      </S.TemporaryFlexBorder>
    </S.Flex>
  );
};
