import React from 'react';
import { useNavigate } from 'react-router-dom';

import { S } from './NotFound.styles';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <S.CenterSection>
      <S.Wrapper>
        <S.TextContainer>
          <S.H1 $color="secondary">404</S.H1>

          <S.B3 $align="center">Hoppsan! Den här sidan finns inte.</S.B3>
        </S.TextContainer>

        <S.Button onClick={navigateToHome} $width={'60%'}>
          Gå till hem
        </S.Button>
      </S.Wrapper>
    </S.CenterSection>
  );
};
