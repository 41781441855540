import styled from 'styled-components';
import { UIElements } from '@ansvar-sakerhet/ui';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.8px solid ${({ theme }) => theme.colors.gray};
`;

const HeaderInner = styled.div`
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 24px;
`;

const IconWrapper = styled(Link)`
  margin-top: 3px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const ProfileInfoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.s};
`;

export const S = {
  ...UIElements,
  HeaderContainer,
  HeaderInner,
  IconWrapper,
  ContentContainer,
  ProfileInfoWrapper,
};
