import { UIElements } from '@ansvar-sakerhet/ui';
import { H5 } from '@ansvar-sakerhet/ui/src/typography/H5';
import { H6 } from '@ansvar-sakerhet/ui/src/typography/H6';
import { styled } from 'styled-components';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  flex-grow: 1;
  height: 90%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RedListedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const RelativeTableContainer = styled.div`
  position: relative;
`;

const SearchErrorContainer = styled.div`
  top: ${({ theme }) => theme.spacing['7xl']};
`;

const SearchErrorTitle = styled(H5)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

const SearchErrorSubTitle = styled(H6)`
  font-weight: normal;
`;

const CenteredIcon = styled(UIElements.Icon)`
  vertical-align: middle;
`;

const TableText = styled.p`
  display: inline;
`;

export const S = {
    ...UIElements,
    CenteredIcon,
    TableText,
    TableContainer,
    HeaderContainer,
    RedListedContainer,
    RelativeTableContainer,
    SearchErrorTitle,
    SearchErrorContainer,
    SearchErrorSubTitle,
};
