const all = ['companies'];

const findAll = (props: { page?: number; search?: string }) => [
  ...all,
  props.page,
  props.search,
];

const findAllWithStatistics = (props: { page?: number; search?: string }) => [
  ...all,
  props.page,
  props.search,
  'only-with-stats',
];

export const companiesKeys = { all, findAll, findAllWithStatistics };
