import { ChangeEvent, useRef, useState } from 'react';
import { useUploadFrameCompanyFile } from '../../../../../../traits/api/frameCompany/uploadFrameCompanyFile';
import { useQueryClient } from '@tanstack/react-query';


export const useReportUpload = (frameCompanyId: string, type: string) => {
  const [fileId, setFileId] = useState<null | string>(null);
  const postFile = useUploadFrameCompanyFile({frameCompanyId, type});
  const inputRef = useRef<HTMLInputElement | null>(null);
  const useQuery = useQueryClient();
  
  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    const res = await postFile.mutateAsync(file);
    useQuery.invalidateQueries({
      queryKey: ['frame-company', frameCompanyId],
    });
    return '';
  };

  return {
    inputRef,
    handleUploadClick,
    handleFileChange,
    fileId,
  };
};
