import { useMutation } from '@tanstack/react-query';
import { useApi } from '../useApi';

export function useGetCompanyCountryStats() {
  const getApi = useApi();

  const getStats = async () => {
    const api = await getApi();
    return await api.companyStatisticsGetCompanyAverage();
  };

  const mutation = useMutation(getStats, {});

  return mutation;
}
