import React from 'react';
import { useTable } from 'react-table';
import { useSubcontractorsStatisticsTableData } from './hooks/useSubcontractorsStatisticsTableData';
import { CompanyWithBackgroundControl } from '@ansvar-sakerhet/api-client';
import { S } from './SubcontractorsStatistics.styles';

export const SubcontractorsStatistics = ({
  subcontractors,
}: {
  subcontractors: CompanyWithBackgroundControl[];
}) => {
  const data = useSubcontractorsStatisticsTableData(subcontractors);
  const table = useTable(data);

  if (data.data.length === 0) {
    return <></>;
  }

  return (
    <S.TContainer>
      <S.TWrapper>
        <S.Table {...table.getTableProps()}>
          <S.THead>
            {table.headerGroups.map((headerGroup) => (
              <S.THeadRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <S.THeadCell
                      {...column.getHeaderProps()}
                      key={column.getHeaderProps().key}
                    >
                      <S.B3 $color="black">{column.render('Header')}</S.B3>
                    </S.THeadCell>
                  );
                })}
              </S.THeadRow>
            ))}
          </S.THead>

          <S.TBody {...table.getTableBodyProps()}>
            {table.rows.map((row) => {
              table.prepareRow(row);

              return (
                <S.TBodyRow {...row.getRowProps()} key={row.getRowProps().key}>
                  {row.cells.map((cell) => {
                    return (
                      <S.TBodyCell
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                      >
                        {cell.render('Cell')}
                      </S.TBodyCell>
                    );
                  })}
                </S.TBodyRow>
              );
            })}
          </S.TBody>
        </S.Table>
      </S.TWrapper>
    </S.TContainer>
  );
};
