import React from 'react';
import { FieldError } from 'react-hook-form';

import { S } from './Checkbox.styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: FieldError | undefined;
}

export const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { label, ...rest } = props;
    return (
      <div>
        <S.Wrapper>
          <S.Input type="checkbox" {...rest} ref={ref} />
          <S.Label>{label}</S.Label>
        </S.Wrapper>
        {props.error && (
          <S.Error>
            <S.B3 $color="error">{props.error.message}</S.B3>
          </S.Error>
        )}
      </div>
    );
  }
);
