import React from 'react';
import { S } from './DownloadFieldFileButton.styles';
import { toast } from '../../../../../../components/Toast';
import { useDeleteFrameCompanyFile } from '../../../../../../traits/api/frameCompany/deleteFrameCompanyFile';
import { useQueryClient } from '@tanstack/react-query';
import { useGetFiles } from '../../../../../../traits/api/file/useFiles';

export const DownloadFieldFileButton = ({
  downloadUrl,
  frameCompanyId,
  type,
}: {
  downloadUrl: string;
  frameCompanyId: string;
  type: string;
}) => {
  const delteFrameCompanyFile = useDeleteFrameCompanyFile({
    frameCompanyId,
    type,
  });
  const useQuery = useQueryClient();
  const getFile = useGetFiles();

  const startDownload = async (e:any) => {
    e.preventDefault();
    if (!downloadUrl) {
      return toast.error('Fil kunde inte hittas');
    }

    const sasToken = await getFile(downloadUrl);
    const link = document.createElement('a');
    link.href = sasToken;
    link.download = 'rapport';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const deleteFile = async (e:any) => {
    e.preventDefault();
    await delteFrameCompanyFile.mutateAsync();
    await useQuery.invalidateQueries({
      queryKey: ['frame-company', frameCompanyId],
    });
  }

  return (
    <div style={{display: 'flex'}}>
    <S.DownloadButton onClick={startDownload}>
      <S.IconWithBg src="Draft" /> Ladda ner fil
    </S.DownloadButton>
    <S.DeleteButton  onClick={deleteFile}>
      <S.IconWithBg src="Trash" />
    </S.DeleteButton>
    
    </div>
  );
};
