import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { controlKeys } from './controls.key';

export function useGetStatistics(inspectionId: string, controlId: string) {
  const getApi = useApi();

  const getControl = async () => {
    const api = await getApi();

    const res = await api.controlStatisticsFindSummary(controlId);
    return res.data;
  };

  const query = useQuery({
    queryKey: controlKeys.findStatistics(inspectionId),
    queryFn: getControl,
  });

  return query;
}
