import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { toast } from '../../../components/Toast';
import { UpdateFrameCompanyDto } from '@ansvar-sakerhet/api-client';

export function useUpdateFrameCompany(companyId: string) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const mutationFn = async (data: UpdateFrameCompanyDto) => {
    const api = await getApi();

    const res = await api.frameCompanyUpdateFrame(companyId, data);

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Företaget har uppdaterats');
    queryClient.invalidateQueries({ queryKey: keys.all });
  };

  const onError = () => {
    toast.error('Något gick fel');
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess,
    onError,
  });

  return mutation;
}
