import React from 'react';
import { parseFaqData } from '../helpers/parseFaqData';
import { S } from '../Faq.styles';

interface TextRendererProps {
  text: string;
}

export const TextRenderer: React.FC<TextRendererProps> = ({ text }) => {
  const { emailAddress, emailText, emailLink } = parseFaqData(text);
  let textToRender = text;

  if (emailLink && emailText && emailAddress) {
    textToRender = textToRender.replace(
      emailLink,
      `<a href="mailto:${emailAddress}" target="_blank" rel="noopener noreferrer">${emailText}</a>`
    );
  }

  return <S.ContentText dangerouslySetInnerHTML={{ __html: textToRender }} />;
};
