import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 15%;
  min-width: 250px;
  border-right: 0.8px solid ${({ theme }) => theme.colors.gray};
`;

const NavigationItem = styled.div<{ $chosen?: boolean }>`
  display: flex;
  padding: 0.8rem;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;
  ${({ $chosen, theme }) => $chosen && `background: ${theme.colors.primary}`}
`;

const NavigationBarFooter = styled.div`
  display: flex;
  padding: 0.8rem;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
  margin-top: auto;
`;

const NavigationItemText = styled.p`
  padding: 0;
  margin: 0;
  display: inline;
`;

export const S = {
  ...UIElements,
  NavigationBarFooter,
  NavigationItemText,
  NavigationItem,
  Container,
};
