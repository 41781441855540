import React from 'react';
import { S } from './DownloadReportButton.styles';
import { toast } from '../../../../../../../../components/Toast';
import { BackgroundControlWithFilesRiskEnum } from '@ansvar-sakerhet/api-client';
import { useGetBGFile } from '../../../../../../../../traits/api/controls/useGetBGFile';

export const DownloadReportButton = ({
  risk,
  downloadUrl,
}: {
  risk: BackgroundControlWithFilesRiskEnum;
  downloadUrl: string;
}) => {
  const getFile = useGetBGFile(downloadUrl);

  const startDownload = async () => {
    if (!downloadUrl) {
      return toast.error('Fil kunde inte hittas');
    }

    const file = await getFile();

    const link = document.createElement('a');
    link.href = file.path;
    link.download = 'rapport';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.DownloadButton
      onClick={startDownload}
      $noRisks={risk}
    >
      <S.IconWithBg src="Draft" /> Ladda ner rapport
    </S.DownloadButton>
  );
};
