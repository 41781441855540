import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { companiesKeys as keys } from './companies.key';
import { toast } from '../../../components/Toast';
import { UpdateCompanyDto } from '@ansvar-sakerhet/api-client';

export function useEditCompany(companyId: string) {
  const getApi = useApi();
  const queryClient = useQueryClient();

  const updateCompany = async (data: UpdateCompanyDto) => {
    const api = await getApi();

    const res = await api.companiesUpdate(companyId, data);

    return res.data;
  };

  const onSuccess = () => {
    toast.success('Företaget har uppdaterats');
    queryClient.invalidateQueries({ queryKey: keys.all });
  };

  const onError = () => {
    toast.error('Något gick fel');
  };

  const mutation = useMutation(updateCompany, {
    onSuccess,
    onError,
  });

  return mutation;
}
