import { useMemo } from 'react';
import {
  ControlWithCounterMeasures,
  DeviationCounterMeasure,
  DeviationCounterMeasureTypeEnum,
  FindMyProjectWithControls,
} from '@ansvar-sakerhet/api-client';
import {
  ControlKeys,
  DEVIATION_FIELDS,
  UsageDescription,
} from '../../../utils/DEVIATION_FIELDS';

export type NonResolvedDeviation = {
  type: DeviationCounterMeasureTypeEnum;
  result: string;
  action: string;
  controlId: string;
};

export type ResolvedDeviation = {
  type: DeviationCounterMeasureTypeEnum;
  result: string;
  action: string;
  controlId: string;
  fixDescription: string;
  fixedAt: string;
  fixedBy: string;
};

export const useDeviations = (
  project: FindMyProjectWithControls | undefined
) => {
  const { nonResolvedActions, resolvedActions } = useMemo(() => {
    const nonResolved: NonResolvedDeviation[] = [];
    const resolved: ResolvedDeviation[] = [];

    if (project) {
      project.controls.forEach((control) => {
        const fieldsToHandle = Object.entries(DEVIATION_FIELDS).filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([_, { shouldHandle }]) => shouldHandle
        );

        fieldsToHandle.forEach(([key, fields]) => {
          if (!fields.shouldHandle) {
            return;
          }

          const actionNeeded = control[key as ControlKeys];
          const counterMeasure = control.deviationCounterMeasures.filter(
            ({ type }) => type === fields.counterMeasureType
          )[0];

          if (counterMeasure) {
            return resolved.push(
              buildResolvedDeviation(control, counterMeasure, key, fields)
            );
          }

          if (!counterMeasure && actionNeeded) {
            return nonResolved.push(
              buildNonResolvedDeviation(control, key, fields)
            );
          }
        });
      });
    }

    return { nonResolvedActions: nonResolved, resolvedActions: resolved };
  }, [project]);

  return { nonResolvedActions, resolvedActions };
};

type ShouldHandleTrue<T> = T extends { shouldHandle: true } ? T : never;

function buildNonResolvedDeviation(
  control: ControlWithCounterMeasures,
  key: string,
  fields: ShouldHandleTrue<UsageDescription>
) {
  const result = control[fields.resultsKey];
  const action = control[fields.actionsKey];
  return {
    type: fields.counterMeasureType,
    result: result ?? 'Okänt',
    action: action ?? 'Okänt',
    controlId: control.controlId,
  };
}

function buildResolvedDeviation(
  control: ControlWithCounterMeasures,
  counterMeasure: DeviationCounterMeasure,
  key: string,
  fields: ShouldHandleTrue<UsageDescription>
) {
  const result = control[fields.resultsKey];
  const action = control[fields.actionsKey];

  return {
    type: fields.counterMeasureType,
    result: result ?? 'Okänt',
    action: action ?? 'Okänt',
    controlId: control.controlId,
    fixedBy: counterMeasure.fixedBy,
    fixedAt: counterMeasure.fixedAt,
    fixDescription: counterMeasure.fixDescription,
  };
}
